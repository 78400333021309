import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import classNames from 'classnames';
// import dateFormat from 'dateformat';

import { round } from 'svs-utils/web';
import { Table2 } from 'svs-utils/react';

class YearlySummaryTable extends Component {
    render() {
        var { clickGroups, years } = this.props;

        var tableHeaders = [
            { key: 'accountId', label: 'ID', dataType: 'number', className: 'centered', width: 40 },
            { key: 'year', label: 'Year', dataType: 'string', width: 70 },
            { key: 'symbol', label: 'Symbol', dataType: 'string', width: 70 },
            { key: 'startDate', label: 'Start Date', dataType: 'date', width: 130 },
            { key: 'endDate', label: 'End Date', dataType: 'date', width: 130 },
            { key: 'profit', label: 'Profit', dataType: 'number', width: 90, format: 'money' },
            { key: 'percent', label: 'Profit %', dataType: 'number', width: 80, format: 'percent' },
            { key: 'multiplied', label: 'Multiplied', dataType: 'number', width: 80, format: 'percent' },
            { key: 'annualized', label: 'Annualized', dataType: 'number', width: 90, format: 'percent' },
            { key: 'averageQuantity', label: 'Avg Quantity', dataType: 'number', className: 'centered', width: 100 },
            { key: 'daysWon', label: 'Days Won', dataType: 'number', className: 'centered', width: 80 },
            { key: 'averageWon', label: 'Avg Won', dataType: 'number', format: 'percent', width: 80 },
            { key: 'daysLost', label: 'Days Lost', dataType: 'number', className: 'centered', width: 80 },
            { key: 'averageLost', label: 'Avg Lost', dataType: 'number', format: 'percent', width: 80 },
            { key: 'winRatio', label: 'Win Ratio', dataType: 'number', className: 'centered', width: 80 },
            { key: 'spyChangePercent', label: 'SPY Change', dataType: 'number', format: 'percent', width: 100 },
            { key: 'alpha', label: 'SPY Alpha', dataType: 'number', format: 'percent', width: 90 },
        ];

        var tableRows = years.map((result) => ({
            ...result,
            rowId: `${result.accountId}-${result.year}`,
            winRatio: round(result.daysWon / result.daysLost, 2),
            className: classNames({ winningTest: result.profit >= 0, losingTest: result.profit < 0 }),
            noContextObject: result,
        }));

        return (
            <Table2 headers={tableHeaders} rows={tableRows} clickGroups={clickGroups} maxHeight={500} />
        );
    }
}

export default YearlySummaryTable;
