import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import classNames from 'classnames';
// import dateFormat from 'dateformat';

// import { round } from 'svs-utils/web';
import { Table2 } from 'svs-utils/react';

class TradesTable extends Component {
    render() {
        var { clickGroups, trades, showAccountName = false } = this.props;

        var tableHeaders = [
            { key: 'dateFilled', label: 'Date', dataType: 'date', width: 140 },
            { key: 'balance', label: 'Starting Balance', dataType: 'number', width: 100, format: 'money' },
            { key: 'trade', label: 'Trade Type', dataType: 'string', width: 80 },
            { key: 'side', label: 'Trade Side', dataType: 'string', width: 80 },
            { key: 'symbol', label: 'Symbol', dataType: 'string', width: 80 },
            { key: 'expiration', label: 'Expiration', dataType: 'date', width: 140 },
            { key: 'strike1', label: 'Strike 1', dataType: 'number', width: 80 },
            { key: 'strike2', label: 'Strike 2', dataType: 'number', width: 80 },
            { key: 'strike3', label: 'Strike 3', dataType: 'number', width: 80 },
            { key: 'strike4', label: 'Strike 4', dataType: 'number', width: 80 },
            { key: 'symbolPrice', label: 'Symbol Price', dataType: 'number', width: 100, format: 'money' },
            { key: 'quantity', label: 'Quantity', dataType: 'number', width: 80, className: 'centered' },
            { key: 'collateral', label: 'Collateral', dataType: 'number', width: 100, format: 'money' },
            { key: 'price', label: 'Price', dataType: 'number', width: 100, format: 'money' },
            { key: 'fees', label: 'Fees', dataType: 'number', width: 100, format: 'money' },
            { key: 'totalPrice', label: 'Total Price', dataType: 'number', width: 100, format: 'money' },
            { key: 'symbolClosePrice', label: 'Symbol Close Price', dataType: 'number', width: 100, format: 'money' },
            { key: 'closePrice', label: 'Close Price', dataType: 'number', width: 100, format: 'money' },
            { key: 'closeFees', label: 'Close Fees', dataType: 'number', width: 100, format: 'money' },
            { key: 'totalClosePrice', label: 'Total Close Price', dataType: 'number', width: 100, format: 'money' },
            { key: 'profit', label: 'Profit', dataType: 'number', format: 'money' },
            { key: 'profitPercent', label: 'Profit Percent', dataType: 'number', width: 100, format: 'percent' },
        ];

        if (showAccountName) {
            tableHeaders.splice(1, 0,
                { key: 'accountName', label: 'Account', dataType: 'string' },
                { key: 'brokerage', label: 'Brokerage', dataType: 'string' },
            );
        }

        var tableRows = trades.map((trade) => ({
            ...trade,
            rowId: trade.tradeId || trade.id,
            className: classNames({ winningTest: trade.profit > 0, losingTest: trade.profit < 0 }),
            noContextObject: trade,
        }));

        return (
            <Table2 headers={tableHeaders} rows={tableRows} clickGroups={clickGroups} maxHeight={500} />
        );
    }
}

export default TradesTable;
