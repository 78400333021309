import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
// import classNames from 'classnames';
import dateFormat from 'dateformat';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, TimeScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import 'chartjs-adapter-date-fns';

import { ajax, getLocalCeiling, getLocalFloor, getQueryParams, parseDate, round } from 'svs-utils/web';
import { JsonViewerDialog } from 'svs-utils/react';

import { RanTestsTable, YearlySummaryTable } from '../../../tables/tables.js';

import './compareResults.scss';

ChartJS.register(TimeScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

class CompareResults extends Component {

    constructor(props) {
        super(props);

        this.state = {
            dialogJson: null,
            tests: [],
            errorMessage: '',
            lastUpdated: null,
            tradesTableOpen: false,
        };
    }

    componentDidMount() {
        this.getTests();
    }

    mapFunc(test) {
        var newTest = {
            ...test,
            params: {
                ...test.params,
                startDate: parseDate(test.params.startDate),
                endDate: parseDate(test.params.endDate),
            },
            startDate: parseDate(test.startDate),
            endDate: parseDate(test.endDate),
            testStarted: parseDate(test.testStarted),
            testEnded: parseDate(test.testEnded),
            tradeResults: test.tradeResults?.map((result) => ({
                ...result,
                name: test.name,
                dateFilled: parseDate(result.dateFilled),
                dateClosed: parseDate(result.dateClosed),
                expiration: parseDate(result.expiration),
                tradeDate: new Date(parseDate(result.dateClosed).setHours(0, 0, 0, 0)),
                endingBalance: result.balance + result.profit,
                endingProfit: round((result.balance + result.profit) - (test.startingBalance || test.params.cash), 2),
                endingProfitPercent: round(((result.balance + result.profit) - (test.startingBalance || test.params.cash)) / (test.startingBalance || test.params.cash) * 100, 1),
                profitPercent: round(result.profitPercent, 1),
            })),
            resultsSummary: test.resultsSummary?.map((result) => ({
                ...result,
                startDate: parseDate(result.startDate),
                endDate: parseDate(result.endDate),
                year: result.year === '0000' ? 'All years' : result.year,
            })),
        };

        // var endingBalances = newTest.tradeResults.map((result) => result.endingBalance);
        // newTest.maxEndingBalance = Math.max(...endingBalances);
        // newTest.minEndingBalance = Math.min(...endingBalances);

        // var profits = newTest.tradeResults.map((result) => result.endingProfit);
        // newTest.maxProfit = Math.max(...profits);
        // newTest.minProfit = Math.min(...profits);

        var profitPercents = newTest.tradeResults.map((result) => result.endingProfitPercent);
        newTest.maxProfitPercent = Math.max(...profitPercents);
        newTest.minProfitPercent = Math.min(...profitPercents);

        return newTest;
    }

    async getTests() {
        var queryParams = getQueryParams();
        var testIds = queryParams.tests;

        if (!testIds) {
            return this.setState({ errorMessage: 'No tests specified' });
        }
        testIds = testIds.split(',');
        if (!testIds.length) {
            return this.setState({ errorMessage: 'No tests specified' });
        }

        var results = await ajax({ endPoint: '/getTestsForComparison', data: { testIds } });
        if (results.result) {
            var tests = results.tests.map((test) => this.mapFunc(test));
            this.setState({ tests, lastUpdated: new Date() });
        } else {
            this.setState({ errorMessage: results.desc });
        }
    }

    render() {
        var { dialogJson, tests, errorMessage, lastUpdated } = this.state;

        var testInfoClickGroups = [
            [
                { name: 'View Params', onClick: (myTest) => this.setState({ dialogJson: myTest.params }) },
                { name: 'View Results JSON', onClick: (myTest) => this.setState({ dialogJson: myTest.results }) },
                { name: 'View Trades JSON', onClick: (myTest) => this.setState({ dialogJson: myTest.tradeResults }) },
                { name: 'View All Test Info', onClick: (myTest) => this.setState({ dialogJson: myTest }) },
            ],
        ];

        var testSummaryClickGroups = [
            [
                { name: 'View Summary JSON', onClick: (trade) => this.setState({ dialogJson: trade }) },
            ],
        ];

        // var tradeInfoClickGroups = [
        //     [
        //         { name: 'View Trade JSON', onClick: (trade) => this.setState({ dialogJson: trade }) },
        //     ],
        // ];

        var openJsonDialog = (json) => this.setState({ dialogJson: json });

        return (
            <React.Fragment>
                {dialogJson && (
                    <JsonViewerDialog close={() => this.setState({ dialogJson: null })} json={JSON.stringifySafe(dialogJson)} />
                )}
                <div className='compareResults'>
                    <div className='testsHeader'>
                        <div className='contentHeader'>Compare Tests</div>
                        <div className='lastUpdatedContainer'>{lastUpdated && `Last Updated: ${dateFormat(lastUpdated, 'mmm dS, yyyy "at" hh:MM:ss tt')}`}</div>
                    </div>
                    {errorMessage || (!!tests.length && (
                        <React.Fragment>
                            <div className='myTableContainer homeBoxShadow'>
                                <RanTestsTable tests={tests} clickGroups={testInfoClickGroups} />
                            </div>
                            <div className='myTestSectionHeader'>Yearly Summary</div>
                            <div className='myTableContainer homeBoxShadow'>
                                <YearlySummaryTable years={tests.reduce((acc, test) => [...acc, ...test.resultsSummary], [])} clickGroups={testSummaryClickGroups} />
                            </div>
                            <div className='myTestSectionHeader'>Chart</div>
                            <div className='myTestChartContainer homeBoxShadow'>
                                <MyChart tests={tests} openJsonDialog={openJsonDialog} />
                            </div>
                            {/* <div className='myTestSectionHeader'>Trades</div>
                            <div className='myTableContainer homeBoxShadow'>
                                {tradesTableOpen ? (
                                    <React.Fragment>
                                        <Input type='button' label='Hide All Trades' onClick={() => this.setState({ tradesTableOpen: false })} />
                                        <TradesTable trades={myTest.tradeResults} clickGroups={tradeInfoClickGroups} />
                                    </React.Fragment>
                                ) : (
                                    <Input type='button' label='Show All Trades' onClick={() => this.setState({ tradesTableOpen: true })} />
                                )}
                            </div> */}
                        </React.Fragment>
                    ))}
                </div>
            </React.Fragment>
        );
    }
}

function MyChart(props) {
    var { tests, openJsonDialog } = props;

    if (!tests.length) {
        return <div>Unable to make chart</div>;
    }

    // tests = [...tests];
    // tests.sort((a, b) => (a.tradeResults[0].tradeDate - b.tradeResults[0].tradeDate));

    var minX = new Date(Math.min(...tests.map((test) => test.tradeResults[0].tradeDate.getTime())));
    minX.setDate(minX.getDate() - 1);
    var maxX = new Date(Math.max(...tests.map((test) => test.tradeResults[test.tradeResults.length - 1].tradeDate.getTime())));
    maxX.setDate(maxX.getDate() + 1);

    var colors = ['#88f', '#da3a37', '#33dc33', '#9c00e5', '#e1e100'];

    var data = {
        datasets: tests.map((test, i) => ({
            label: test.testId,
            data: test.tradeResults,
            borderColor: colors[i % colors.length],
            backgroundColor: colors[i % colors.length],
            parsing: { xAxisKey: 'tradeDate', yAxisKey: 'endingProfitPercent' },
            pointRadius: 2,
            pointHitRadius: 2,
            pointHoverRadius: 4,
        })),
    };

    var options = {
        onClick: (event, elements, chart) => elements.length && openJsonDialog(elements.reduce((acc, e) => ({ ...acc, [e.element.$context.raw.accountId]: e.element.$context.raw }), {})),
        maintainAspectRatio: false,
        scales: {
            x: {
                type: 'time',
                min: minX,
                max: maxX,
                time: { unit: 'day', displayFormats: { day: 'MMM dd yy' } },
            },
            y: {
                type: 'linear',
                // min: -100,
                min: getLocalFloor(Math.min(...tests.map((test) => test.minProfitPercent))),
                max: getLocalCeiling(Math.max(...tests.map((test) => test.maxProfitPercent))),
                title: { display: true, text: 'Profit %' }
            },
        },
        plugins: {
            tooltip: {
                intersect: false,
                mode: 'nearest',
                axis: 'x',
                position: 'nearest',
                itemSort: (a, b) => b.raw.endingProfitPercent - a.raw.endingProfitPercent,
                callbacks: {
                    title: (elements) => dateFormat(elements[0].raw.tradeDate, 'ddd mmm d, yyyy'),
                    label: (element) => {
                        var row = element.raw;
                        return `Day: ${row.profitPercent < 0 ? '' : '+'}${row.profitPercent.toFixed(1)}%, Total: ${row.endingProfitPercent < 0 ? '' : '+'}${row.endingProfitPercent}%`;
                    },
                },
            },
        },
        hover: { intersect: false, mode: 'x' }, // Show all points on x-axis
    };

    return (
        <div className='tradeResultsChartContainer'>
            <Line options={options} data={data} />
        </div>
    );
}

export default CompareResults;
