import React, { Component } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// import classNames from 'classnames';
import dateFormat from 'dateformat';

import { ajax, parseDate, sleep } from 'svs-utils/web';
import { Dialog, Input, JsonViewerDialog, NoContextMenu } from 'svs-utils/react';

import { BotSettingsDialog } from '../../../dialog/dialogs.js';
import { RanTestsTable } from '../../../tables/tables.js';

import './runTests.scss';

class RunTests extends Component {

    static async startRunTestsWebSocket() {
        if (!RunTests.ws) {
            RunTests.ws = 1;
            var results = await ajax({ endPoint: '/requestWsRunTestsHallPass' });
            if (!results.result) {
                RunTests.ws = null;
                alert(results.desc);
                return false;
            }

            var protocol = window.location.protocol === 'http:' ? 'ws' : 'wss';
            var host = window.location.host;
            if (host.startsWith('dev.') && host.endsWith(':2999')) {
                host = host.replace(':2999', ':3000');
            }

            RunTests.ws = new WebSocket(`${protocol}://${host}/api/ws/runTests?token=${results.token}`);
            RunTests.ws.addEventListener('open', (event) => console.debug('run tests web socket connected', event));
            RunTests.ws.addEventListener('message', (event) => console.debug('new message', event));
            RunTests.ws.addEventListener('error', (...args) => console.debug('error connecting ws', args));
            RunTests.ws.addEventListener('close', (event) => console.debug('ws close event', event));

            // on will unmount, close the ws
        }

        return true;
    }

    constructor(props) {
        super(props);

        this.state = {
            dialogJson: null,
            dialogJsonLabel: '',
            dialogCreateTest: null,
            dialogRenameTest: null,
            ranTests: [],
            runningTests: [],
            deletedTests: [],
            errorMessage: '',
            lastUpdated: null,
            showDeletedTests: false,
        };
    }

    componentDidMount() {
        this.getTests();
        this.getDeletedTests();
    }

    componentWillUnmount() {
        if (this.wsOnMessageFunc) {
            RunTests.ws.removeEventListener('message', this.wsOnMessageFunc);
            console.debug('removed message listener');
        }
    }

    mapFunc(test) {
        return {
            ...test,
            params: {
                ...test.params,
                startDate: parseDate(test.params.startDate),
                endDate: parseDate(test.params.endDate),
            },
            testStarted: parseDate(test.testStarted),
            testEnded: parseDate(test.testEnded),
        };
    }

    async getTests() {
        var results = await ajax({ endPoint: '/getRanTests' });
        if (results.result) {
            var ranTests = results.ranTests.map(this.mapFunc);
            var runningTests = results.runningTests.map(this.mapFunc);

            if (runningTests.length) {
                await this.startWebSocket();
            }

            this.setState({ ranTests, runningTests, lastUpdated: new Date() });
        } else {
            this.setState({ errorMessage: results.desc });
        }
    }

    async getDeletedTests() {
        var { user } = this.props;
        if (!user.isGodmode) {
            return;
        }

        var results = await ajax({ endPoint: '/getRanTests', data: { deleted: 'Y' } });
        if (results.result) {
            var deletedTests = results.ranTests.map(this.mapFunc);
            this.setState({ deletedTests });
        }
    }

    async startWebSocket() {
        await RunTests.startRunTestsWebSocket();
        if (RunTests.ws) {
            while (!RunTests.ws.readyState) {
                await sleep(10);
            }

            if (!this.wsOnMessageFunc) {
                this.wsOnMessageFunc = (...args) => this.handleWsMessage(...args);
                RunTests.ws.addEventListener('message', this.wsOnMessageFunc);
                console.debug('attached message listener');
            }
        }
    }

    async handleWsMessage(event) {
        var { ranTests, runningTests } = this.state;

        var data = JSON.parse(event.data);
        var test = null;
        var testIndex;
        switch (data.type) {
            case 'errorMessage':
                alert(data.message);
                break;
            case 'testStarted':
                test = runningTests.find((t) => t.hash === data.test.hash);
                if (!test) {
                    runningTests.push(this.mapFunc(data.test));
                    this.setState({ dialogCreateTest: null, runningTests });
                }
                break;
            case 'runningTestUpdate':
                test = runningTests.find((t) => t.hash === data.hash);
                if (test) {
                    if (data.results.currentTime) {
                        data.results.currentTime = parseDate(data.results.currentTime);
                    }

                    test.results = { ...test.results, ...data.results };
                    this.setState({ runningTests });
                }
                break;
            case 'testEnded':
                testIndex = runningTests.findIndex((t) => t.hash === data.test.hash);
                if (testIndex >= 0) {
                    test = runningTests.splice(testIndex, 1)[0];
                    test = { ...test, status: 'done', ...data };

                    ranTests.push(this.mapFunc(test));
                    ranTests.sort((a, b) => b.testStarted - a.testStarted);
                    this.setState({ ranTests, runningTests });
                }
                break;
            case 'testError':
                alert(`There was an error running the test. Steven has been notified and the test will be marked as deleted.\n\nError: ${data.test.message}`);
                testIndex = runningTests.findIndex((t) => t.hash === data.test.hash);
                if (testIndex >= 0) {
                    runningTests.splice(testIndex, 1);

                    this.setState({ runningTests });
                }
                break;
            default: break;
        }
    }

    async submitCreateNewTest(params) {
        await this.startWebSocket();
        RunTests.ws.send(JSON.stringify({ type: 'startNewTest', params }));
    }

    async submitTestRename() {
        var { dialogRenameTest } = this.state;

        if (!dialogRenameTest.name) {
            return alert('A new name is required');
        }

        var results = await ajax({ endPoint: '/renameTest', data: { testId: dialogRenameTest.testId, newName: dialogRenameTest.name } });
        if (results.result) {
            var { ranTests } = this.state;

            var test = ranTests.find((test) => test.testId === dialogRenameTest.testId);
            if (test) {
                test.name = dialogRenameTest.name;
                this.setState({ ranTests, dialogRenameTest: null });
            }
        } else {
            alert(results.desc);
        }
    }

    async handleDeleteTest(testId) {
        if (confirm('Are you sure you want to delete this test?')) { // eslint-disable-line no-restricted-globals
            var results = await ajax({ endPoint: '/deleteTest', data: { testId } });
            if (results.result) {
                var { ranTests } = this.state;

                var index = ranTests.findIndex((test) => test.testId === testId);
                if (index >= 0) {
                    ranTests.splice(index, 1);
                    this.setState({ ranTests });
                }
            } else {
                alert(results.desc);
            }
        }
    }

    navigateTo(path, event = {}) {
        var { navigate } = this.props;

        if (event.metaKey || event.ctrlKey) {
            window.open(path, '_blank');
        } else {
            navigate(path);
        }
    }

    render() {
        var { user } = this.props;
        var { dialogJson, dialogJsonLabel, dialogCreateTest, deletedTests, ranTests, runningTests, errorMessage, lastUpdated, dialogRenameTest, showDeletedTests } = this.state;

        var ranTestClickGroups = [
            [
                { name: 'Go To Full Results', onClick: (test, event) => this.navigateTo(`/viewresults/${test.hash}`, event), shouldShow: (test) => test.status === 'done' },
            ],
            [
                { name: 'View Params', onClick: (test) => this.setState({ dialogJson: test.params, dialogJsonLabel: 'Params' }) },
                { name: 'View Results JSON', onClick: (test) => this.setState({ dialogJson: test.results, dialogJsonLabel: 'Results' }) },
                { name: 'View All Test Info', onClick: (test) => this.setState({ dialogJson: test, dialogJsonLabel: 'All Test Info' }) },
            ],
            [
                { name: 'Rename Test', onClick: (test) => this.setState({ dialogRenameTest: { testId: test.testId, name: test.name } }), shouldShow: (test) => test.status === 'done' },
                { name: 'Delete Test', onClick: (test) => this.handleDeleteTest(test.testId), shouldShow: (test) => test.status === 'done' },
            ],
        ];

        return (
            <React.Fragment>
                {dialogJson && (
                    <JsonViewerDialog label={dialogJsonLabel} close={() => this.setState({ dialogJson: null, dialogJsonLabel: '' })} json={JSON.stringifySafe(dialogJson)} />
                )}
                {dialogCreateTest && (
                    <BotSettingsDialog
                        close={() => this.setState({ dialogCreateTest: null })}
                        submit={(params) => this.submitCreateNewTest(params)}
                    />
                )}
                {dialogRenameTest && (
                    <Dialog
                        close={() => this.setState({ dialogRenameTest: null })}
                        header='Rename Test'
                        height={150}
                        width={350}
                    >
                        <Input type='text' label='Rename Test' value={dialogRenameTest.name} onChange={(name) => this.setState({ dialogRenameTest: { ...dialogRenameTest, name } })} />
                        <Input type='button' label='Submit' onClick={() => this.submitTestRename()} />
                    </Dialog>
                )}
                <div className='tests'>
                    <div className='testsHeader'>
                        <div className='contentHeader'>Tests</div>
                        <div className='lastUpdatedContainer'>{lastUpdated && `Last Updated: ${dateFormat(lastUpdated, 'mmm dS, yyyy "at" hh:MM:ss tt')}`}</div>
                    </div>
                    {errorMessage || (
                        <React.Fragment>
                            <div className='testsTypeHeader'>Running Tests</div>
                            {runningTests.map((runningTest) => <RunningTestContainer key={runningTest.testId} runningTest={runningTest} clickGroups={ranTestClickGroups} />)}
                            <div className='createNewButtonContainer'>
                                <Input type='button' label='Create New Test' onClick={() => this.setState({ dialogCreateTest: {} })} />
                            </div>
                            <div className='testsTypeHeader'>Ran Tests</div>
                            <div className='ranTestsTableContainer homeBoxShadow'>
                                <RanTestsTable tests={ranTests} clickGroups={ranTestClickGroups} showCompare={true} />
                            </div>
                            {user.isGodmode && (
                                <React.Fragment>
                                    <div className='testsTypeHeader'>Deleted Tests</div>
                                    <div className='ranTestsTableContainer homeBoxShadow'>
                                        {showDeletedTests ? (
                                            <React.Fragment>
                                                <Input type='button' label='Hide Deleted Tests' onClick={() => this.setState({ showDeletedTests: false })} />
                                                <RanTestsTable tests={deletedTests} clickGroups={ranTestClickGroups} />
                                            </React.Fragment>
                                        ) : (
                                            <Input type='button' label='Show Deleted Tests' onClick={() => this.setState({ showDeletedTests: true })} />
                                        )}
                                    </div>
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )}
                </div>
            </React.Fragment>
        );
    }
}

class RunningTestContainer extends Component {
    state = {};

    render() {
        var { runningTest, clickGroups } = this.props;

        var progressPercent = '0%';
        var currentTime = runningTest.results.currentTime;
        if (currentTime) {
            var totalTimeDiff = runningTest.params.endDate - runningTest.params.startDate;
            var completedTime = currentTime - runningTest.params.startDate;
            var progress = completedTime / totalTimeDiff;
            progressPercent = (progress * 100).toFixed(2) + '%';
        }

        return (
            <NoContextMenu groups={clickGroups} object={runningTest}>
                <div className='runningTestContainer homeBoxShadow'>
                    <RanTestsTable tests={[runningTest]} clickGroups={clickGroups} showRowCount={false} showCurrentDate={true} />
                    <div className='runningTestProgressBarContainer'>
                        <div className='runningTestProgressBar' style={{ width: progressPercent }}></div>
                    </div>
                </div>
            </NoContextMenu>
        )
    }
}

export default function RunTestsWrapper(props) {
    var navigate = useNavigate();
    var urlParams = useParams();

    return <RunTests {...props} navigate={navigate} urlParams={urlParams} />;
}
