import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import classNames from 'classnames';
// import dateFormat from 'dateformat';

import { ajax } from 'svs-utils/web';
import { Input } from 'svs-utils/react';

import './emergencyActions.scss';

class EmergencyActions extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentTab: 'tradeInsert',
            tradeInsertFields: {},
        };

        this.tabs = [
            { value: 'tradeInsert', label: 'Trade Insert', content: () => this.renderTradeInsert() },
            { value: 'two', label: 'Two', content: () => <div>Content for Two</div> },
            { value: 'three', label: 'Three', content: () => <div>Content for Three</div> },
        ];
    }

    renderTradeInsert() {
        var { tradeInsertFields } = this.state;

        var accounts = [
            { value: 1, label: 'Steven TD' },
            { value: 2, label: 'Steven ETrade' },
            { value: 3, label: 'Shared' },
            { value: 4, label: 'Ryan' },
            { value: 5, label: 'Matt' },
        ];
        var tradeTypes = [{ value: 'iron condor', label: 'Iron Condor' }];
        var sideTypes = [{ value: 'credit', label: 'Credit' }];

        tradeInsertFields.account = tradeInsertFields.account || 1;
        tradeInsertFields.balance = tradeInsertFields.balance || 0;
        tradeInsertFields.trade = tradeInsertFields.trade || 'iron condor';
        tradeInsertFields.side = tradeInsertFields.side || 'credit';
        tradeInsertFields.symbol = tradeInsertFields.symbol || 'SPXW';
        tradeInsertFields.expiration = tradeInsertFields.expiration || new Date();
        tradeInsertFields.strike1 = tradeInsertFields.strike1 || '';
        tradeInsertFields.strike2 = tradeInsertFields.strike2 || '';
        tradeInsertFields.strike3 = tradeInsertFields.strike3 || '';
        tradeInsertFields.strike4 = tradeInsertFields.strike4 || '';
        tradeInsertFields.symbolPrice = tradeInsertFields.symbolPrice || '';
        tradeInsertFields.quantity = tradeInsertFields.quantity || '';
        tradeInsertFields.orderId = tradeInsertFields.orderId || '';
        tradeInsertFields.filledDate = tradeInsertFields.filledDate || new Date();
        tradeInsertFields.collateral = tradeInsertFields.collateral || '';
        tradeInsertFields.pricePerCondor = tradeInsertFields.pricePerCondor || '';
        tradeInsertFields.feesPerCondor = tradeInsertFields.feesPerCondor || '';

        var getStateFunc = (key) => {
            return (value) => {
                var { tradeInsertFields } = this.state;
                this.setState({ tradeInsertFields: { ...tradeInsertFields, [key]: value } });
            }
        }

        return (
            <div>
                <div className='header'>Insert a Trade into the tradeResults table</div>
                <div style={{ maxWidth: 450 }}>
                    <Input label='Account' type='select' selectItems={accounts} value={tradeInsertFields.account} onChange={getStateFunc('account')} />
                    <Input label='Balance' type='number' value={tradeInsertFields.balance} onChange={getStateFunc('balance')} />
                    <Input label='Trade' type='select' selectItems={tradeTypes} value={tradeInsertFields.trade} onChange={getStateFunc('trade')} />
                    <Input label='Side' type='select' selectItems={sideTypes} value={tradeInsertFields.side} onChange={getStateFunc('side')} />
                    <Input label='Symbol' type='text' value={tradeInsertFields.symbol} onChange={getStateFunc('symbol')} />
                    <Input label='Expiration' type='date' value={tradeInsertFields.expiration} onChange={getStateFunc('expiration')} />
                    <Input label='Strike 1' type='number' value={tradeInsertFields.strike1} onChange={getStateFunc('strike1')} />
                    <Input label='Strike 2' type='number' value={tradeInsertFields.strike2} onChange={getStateFunc('strike2')} />
                    <Input label='Strike 3' type='number' value={tradeInsertFields.strike3} onChange={getStateFunc('strike3')} />
                    <Input label='Strike 4' type='number' value={tradeInsertFields.strike4} onChange={getStateFunc('strike4')} />
                    <Input label='Symbol Price' type='number' value={tradeInsertFields.symbolPrice} onChange={getStateFunc('symbolPrice')} />
                    <Input label='Quantity' type='number' value={tradeInsertFields.quantity} onChange={getStateFunc('quantity')} />
                    <Input label='Order ID' type='text' value={tradeInsertFields.orderId} onChange={getStateFunc('orderId')} />
                    <Input label='Filled Date' type='datetime' value={tradeInsertFields.filledDate} onChange={getStateFunc('filledDate')} />
                    <Input label='Collateral' type='number' value={tradeInsertFields.collateral} onChange={getStateFunc('collateral')} />
                    <Input label='Price Per Condor' type='number' value={tradeInsertFields.pricePerCondor} onChange={getStateFunc('pricePerCondor')} />
                    <Input label='Fees Per Condor' type='number' value={tradeInsertFields.feesPerCondor} onChange={getStateFunc('feesPerCondor')} />
                    <Input label='Submit Trade' type='button' onClick={() => this.submitTradeInsert()} />
                </div>
            </div>
        );
    }

    async submitTradeInsert() {
        var { tradeInsertFields } = this.state;

        if (![1, 2, 3, 4, 5].includes(tradeInsertFields.account)) {
            return alert('Invalid account');
        }
        if (tradeInsertFields.balance <= 0) {
            return alert('Invalid balance');
        }
        if (tradeInsertFields.trade !== 'iron condor') {
            return alert('Invalid trade type');
        }
        if (tradeInsertFields.side !== 'credit') {
            return alert('Invalid side type');
        }
        if (tradeInsertFields.symbol !== 'SPXW') {
            return alert('Only SPXW for now');
        }
        tradeInsertFields.expiration.setHours(16, 15, 0, 0);
        if (tradeInsertFields.expiration < new Date()) {
            return alert('Expiration must be in the future');
        }
        if (tradeInsertFields.strike1 <= 0) {
            return alert('Invalid strike 1');
        }
        if (tradeInsertFields.strike2 <= 0) {
            return alert('Invalid strike 2');
        }
        if (tradeInsertFields.strike3 <= 0) {
            return alert('Invalid strike 3');
        }
        if (tradeInsertFields.strike4 <= 0) {
            return alert('Invalid strike 4');
        }
        if (tradeInsertFields.symbolPrice <= 0) {
            return alert('Invalid symbol price');
        }
        if (tradeInsertFields.quantity <= 0) {
            return alert('Invalid quantity');
        }
        if (tradeInsertFields.filledDate > new Date()) {
            return alert('Filled date must be in the past');
        }
        if (tradeInsertFields.collateral <= 0) {
            return alert('Invalid collateral');
        }
        if (tradeInsertFields.pricePerCondor <= 0) {
            return alert('Invalid price per condor');
        }

        var results = await ajax({ endPoint: '/emergencyInsertTrade', data: { tradeInsertFields } });
        if (results.result) {
            this.setState({ tradeInsertFields: {} });
        } else {
            alert(results.desc);
        }
    }

    render() {
        var { currentTab } = this.state;

        var selectedTab = this.tabs.find((tab) => tab.value === currentTab);

        return (
            <div className='emergencyContainer'>
                <div className='emergencyHeader'>
                    <div className='contentHeader'>Emergency Actions</div>
                </div>
                <div className='emergencyContent'>
                    <div>
                        <div className='emergencyActionsList homeBoxShadow'>
                            {this.tabs.map((tab) => (
                                <div
                                    className={classNames({ selected: currentTab === tab.value })}
                                    key={tab.value}
                                    onClick={() => this.setState({ currentTab: tab.value })}
                                >
                                    {tab.label}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='emergencyActionContent homeBoxShadow'>{selectedTab.content()}</div>
                </div>
            </div>
        );
    }
}

export default EmergencyActions;
