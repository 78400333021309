import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
// import classNames from 'classnames';
import dateFormat from 'dateformat';

import { ajax, parseDate } from 'svs-utils/web';
import { createAlert, Dialog, Input, JsonViewerDialog } from 'svs-utils/react';

import { TradesTable } from '../../../tables/tables.js';

import './trades.scss';

function Trades(props) {
    let [dialogJson, setDialogJson] = useState(null);
    let [dialogCloseTrade, setDialogCloseTrade] = useState({ trade: null, limit: 0 });
    let [accounts, setAccounts] = useState([{ value: 'all', label: 'All' }]);
    let [selectedAccount, setSelectedAccount] = useState('all');
    let [openTrades, setOpenTrades] = useState([]);
    let [closedTrades, setClosedTrades] = useState([]);
    let [errorMessage, setErrorMessage] = useState('');
    let [lastUpdated, setLastUpdated] = useState(null);

    useEffect(() => {
        getTrades();
    }, []);

    let getTrades = async () => {
        let results = await ajax({ endPoint: '/getTrades' });
        if (results.result) {
            let mapFunc = (trade) => ({
                ...trade,
                dateFilled: parseDate(trade.dateFilled),
                expiration: parseDate(trade.expiration),
            });

            let openTrades = results.openTrades.map(mapFunc);
            let closedTrades = results.closedTrades.map(mapFunc);

            let accounts = [...openTrades, ...closedTrades]
                .map((trade) => trade.accountName)
                .filter((v, i, a) => (i === a.indexOf(v)))
                .map((accountName) => ({ value: accountName, label: accountName }));
            accounts.splice(0, 0, { value: 'all', label: 'All' });

            setAccounts(accounts);
            setOpenTrades(openTrades);
            setClosedTrades(closedTrades);
            setLastUpdated(new Date());
        } else {
            setErrorMessage(results.desc);
        }
    };

    let handleCloseTradeSubmit = async () => {
        if (!confirm('Are you sure you want to close this trade?')) { // eslint-disable-line no-restricted-globals
            return;
        }

        var { trade, limit } = dialogCloseTrade;
        if (limit < 0) {
            return createAlert('Limit cannot be negative');
        }

        var results = await ajax({ endPoint: '/submitCloseTrade', data: { accountId: trade.accountId, limit, tradeId: trade.tradeId } });
        if (results.result) {
            createAlert('Successfully submitted close request. Check the logs or your account for the status.');
            setDialogCloseTrade({});
        } else {
            createAlert(results.desc);
        }
    };

    if (selectedAccount !== 'all') {
        closedTrades = closedTrades.filter((trade) => trade.accountName === selectedAccount);
        openTrades = openTrades.filter((trade) => trade.accountName === selectedAccount);
    }

    var tradeClickGroups = [
        [
            // { name: 'Edit', onClick: (object) => this.setState({ editBotDialogOpen: true, editBotParams: { ...object, ...object.strategyInfo } }) },
            { name: 'Close Trade', onClick: (trade) => setDialogCloseTrade({ ...dialogCloseTrade, trade }), shouldShow: (trade) => (trade.status === 'open') },
            { name: 'View Extra Info', onClick: (trade) => setDialogJson(trade.extraInfo) },
            { name: 'View Trade JSON', onClick: (trade) => setDialogJson(trade) },
        ]
    ];

    return (
        <React.Fragment>
            {dialogJson && (
                <JsonViewerDialog close={() => setDialogJson(null)} json={dialogJson} />
            )}
            {dialogCloseTrade.trade && (
                <Dialog
                    className='dialogViewJson'
                    close={() => setDialogCloseTrade({})}
                    header='Close Trade Request'
                    height={195}
                    width={350}
                >
                    <div style={{ height: '100%', overflow: 'auto' }}>
                        <div style={{ marginBottom: 10 }}>You are submitting a request to close this trade for account: {dialogCloseTrade.trade.accountName}</div>
                        <Input
                            type='number'
                            label='Limit (Per Condor)'
                            value={dialogCloseTrade.limit == null ? 0 : dialogCloseTrade.limit}
                            onChange={(limit) => setDialogCloseTrade({ ...dialogCloseTrade, limit })}
                        />
                        <Input type='button' label='Submit' onClick={handleCloseTradeSubmit} />
                    </div>
                </Dialog>
            )}
            <div className='trades'>
                <div className='tradesHeader'>
                    <div className='contentHeader'>Trades</div>
                    <div className='lastUpdatedContainer'>{lastUpdated && `Last Updated: ${dateFormat(lastUpdated, 'mmm dS, yyyy "at" hh:MM:ss tt')}`}</div>
                </div>
                <div className='filterContainer'>
                    Filter
                    <Input className='filterAccountSelect' type='select' selectItems={accounts} onChange={setSelectedAccount} value={selectedAccount} />
                </div>
                {errorMessage || (
                    <React.Fragment>
                        <div className='tradesTypeHeader'>Open Trades</div>
                        {!!openTrades.length && (
                            <div className='myTableContainer homeBoxShadow'>
                                <TradesTable trades={openTrades} clickGroups={tradeClickGroups} showAccountName={true} />
                            </div>
                        )}
                        <div className='tradesTypeHeader'>Closed Trades</div>
                        <div className='myTableContainer homeBoxShadow'>
                            <TradesTable trades={closedTrades} clickGroups={tradeClickGroups} showAccountName={true} />
                        </div>
                    </React.Fragment>
                )}
            </div>
        </React.Fragment>
    );
}

export default Trades;
