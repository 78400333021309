/* eslint-disable no-loop-func */
import React, { Component } from 'react';
import classNames from 'classnames';
import dateFormat from 'dateformat';

import { JsonViewerDialog, NoContextMenu } from 'svs-utils/react';
import { numberWithCommas, parseDate, round } from 'svs-utils/web';

import './optionChains.scss';

class OptionChains extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedExpiration: null,
            selectedStrike: null,
            dialogJson: null,
        };
    }

    handleExpirationClick(expiration) {
        var { selectedExpiration } = this.state;

        if (selectedExpiration === expiration) {
            this.setState({ selectedExpiration: null })
        } else {
            this.setState({ selectedExpiration: expiration })
        }
    }

    handleChainScroll(event) {
        var parent = event.target.parentElement;

        var strikesDiv = parent.querySelector('.chainStrikes');
        var otherDiv = null;
        var otherDiv2 = null;
        if (event.target.classList.contains('chainStrikes')) {
            otherDiv = parent.querySelector('.chainCalls');
            otherDiv2 = parent.querySelector('.chainPuts');
        } else if (event.target.classList.contains('chainCalls')) {
            otherDiv = parent.querySelector('.chainPuts');
        } else {
            otherDiv = parent.querySelector('.chainCalls');
        }

        var titleDiv1 = parent.querySelector('.chainTitles.chainCall');
        var titleDiv2 = parent.querySelector('.chainTitles.chainPut');

        if (!event.target.classList.contains('chainStrikes')) {
            var scrollLeft = event.target.scrollLeft;
            otherDiv.scrollLeft = scrollLeft;
            titleDiv1.scrollLeft = scrollLeft;
            titleDiv2.scrollLeft = scrollLeft;
        }

        var scrollTop = event.target.scrollTop;
        otherDiv.scrollTop = scrollTop;
        if (otherDiv2) {
            otherDiv2.scrollTop = scrollTop;
        }
        strikesDiv.scrollTop = scrollTop;
    }

    renderChainRow(chain = null) {
        var { sourceData } = this.props;

        var columns = [
            { key: 'bid', width: 70 },
            { key: 'bidSize', width: 70 },
            { key: 'mid', width: 70 },
            { key: 'ask', width: 70 },
            { key: 'askSize', width: 70 },
            { key: 'last', width: 70 },
            { key: 'change', width: 70 },
            { key: 'changePer', width: 100 },
            { key: 'open', width: 70 },
            // { key: 'breakEven', width: 70 },
            // { key: 'impliedVol', width: 70 },
            { key: 'volume', width: 70 },
            { key: 'openInt', width: 70 },
            { key: 'delta', width: 60 },
            { key: 'gamma', width: 60 },
            { key: 'theta', width: 70 },
            { key: 'vega', width: 60 },
            { key: 'rho', width: 60 },
            { key: 'greeksUpdated', width: 150 },
        ];

        var blankValue = '--';
        var otherInfo = null;
        var greeks = null;

        var values = {};
        if (!chain) {
            values = {
                bid: 'Bid',
                bidSize: 'Bid Size',
                mid: 'Mid',
                ask: 'Ask',
                askSize: 'Ask Size',
                last: 'Last',
                change: 'Change',
                changePer: 'Change %',
                open: 'Open',
                breakEven: 'Break Even',
                impliedVol: 'Impl. Vol',
                volume: 'Volume',
                openInt: 'Open Int',
                delta: 'Delta',
                gamma: 'Gamma',
                theta: 'Theta',
                vega: 'Vega',
                rho: 'Rho',
                greeksUpdated: 'Greeks Updated'
            };
        } else if (sourceData.source === 'tradier') {
            greeks = chain.otherInfo?.greeks || {};
            otherInfo = chain.otherInfo || {};
            values = {
                bid: chain.bid != null ? numberWithCommas(chain.bid, 2) : blankValue,
                bidSize: otherInfo.bidsize != null ? `x${otherInfo.bidsize.toFixed(0)}` : blankValue,
                mid: chain.mid != null ? numberWithCommas(chain.mid, 2) : blankValue,
                ask: chain.ask != null ? numberWithCommas(chain.ask, 2) : blankValue,
                askSize: otherInfo.asksize != null ? `x${otherInfo.asksize.toFixed(0)}` : blankValue,
                last: otherInfo.last != null ? numberWithCommas(otherInfo.last, 2) : blankValue,
                change: otherInfo.change != null ? numberWithCommas(otherInfo.change, 2) : blankValue,
                changePer: otherInfo.change_percentage != null ? numberWithCommas(otherInfo.change_percentage, 2) + '%' : blankValue,
                open: otherInfo.open != null ? numberWithCommas(otherInfo.open, 2) : blankValue,
                breakEven: blankValue,
                impliedVol: blankValue,
                volume: otherInfo.volume != null ? numberWithCommas(otherInfo.volume, 0) : blankValue,
                openInt: otherInfo.open_interest != null ? numberWithCommas(otherInfo.open_interest, 0) : blankValue,
                delta: greeks.delta != null ? round(greeks.delta, 4) : blankValue,
                gamma: greeks.gamma != null ? round(greeks.gamma, 4) : blankValue,
                theta: greeks.theta != null ? round(greeks.theta, 4) : blankValue,
                vega: greeks.vega != null ? round(greeks.vega, 4) : blankValue,
                rho: greeks.rho != null ? round(greeks.rho, 4) : blankValue,
                greeksUpdated: greeks.updated_at != null ? dateFormat(parseDate(greeks.updated_at), 'mm/dd/yy hh:MM:ss tt') : blankValue
            };
        } else if (sourceData.source === 'tdAmeritrade') {
            otherInfo = chain.otherInfo || {};
            values = {
                bid: chain.bid != null ? numberWithCommas(chain.bid, 2) : blankValue,
                bidSize: otherInfo.bidSize != null ? `x${otherInfo.bidSize.toFixed(0)}` : blankValue,
                mid: chain.mid != null ? numberWithCommas(chain.mid, 2) : blankValue,
                ask: chain.ask != null ? numberWithCommas(chain.ask, 2) : blankValue,
                askSize: otherInfo.askSize != null ? `x${otherInfo.askSize.toFixed(0)}` : blankValue,
                last: otherInfo.last != null ? numberWithCommas(otherInfo.last, 2) : blankValue,
                change: otherInfo.netChange != null ? numberWithCommas(otherInfo.netChange, 2) : blankValue,
                changePer: otherInfo.percentChange != null ? numberWithCommas(otherInfo.percentChange, 2) + '%' : blankValue,
                open: otherInfo.openPrice != null ? numberWithCommas(otherInfo.openPrice, 2) : blankValue,
                breakEven: blankValue,
                impliedVol: blankValue,
                volume: otherInfo.totalVolume != null ? numberWithCommas(otherInfo.totalVolume, 0) : blankValue,
                openInt: otherInfo.openInterest != null ? numberWithCommas(otherInfo.openInterest, 0) : blankValue,
                delta: otherInfo.delta != null ? round(otherInfo.delta, 4) : blankValue,
                gamma: otherInfo.gamma != null ? round(otherInfo.gamma, 4) : blankValue,
                theta: otherInfo.theta != null ? round(otherInfo.theta, 4) : blankValue,
                vega: otherInfo.vega != null ? round(otherInfo.vega, 4) : blankValue,
                rho: otherInfo.rho != null ? round(otherInfo.rho, 4) : blankValue,
                greeksUpdated: blankValue
            };
        } else if (sourceData.source === 'eTrade') {
            greeks = chain.otherInfo?.OptionGreeks || {};
            otherInfo = chain.otherInfo || {};
            values = {
                bid: chain.bid != null ? numberWithCommas(chain.bid, 2) : blankValue,
                bidSize: otherInfo.bidSize != null ? `x${otherInfo.bidSize.toFixed(0)}` : blankValue,
                mid: chain.mid != null ? numberWithCommas(chain.mid, 2) : blankValue,
                ask: chain.ask != null ? numberWithCommas(chain.ask, 2) : blankValue,
                askSize: otherInfo.askSize != null ? `x${otherInfo.askSize.toFixed(0)}` : blankValue,
                last: otherInfo.lastPrice != null ? numberWithCommas(otherInfo.lastPrice, 2) : blankValue,
                change: otherInfo.netChange != null ? numberWithCommas(otherInfo.netChange, 2) : blankValue,
                changePer: otherInfo.percentChange != null ? numberWithCommas(otherInfo.percentChange, 2) + '%' : blankValue,
                open: otherInfo.openPrice != null ? numberWithCommas(otherInfo.openPrice, 2) : blankValue,
                breakEven: blankValue,
                impliedVol: greeks.iv,
                volume: otherInfo.volume != null ? numberWithCommas(otherInfo.volume, 0) : blankValue,
                openInt: otherInfo.openInterest != null ? numberWithCommas(otherInfo.openInterest, 0) : blankValue,
                delta: greeks.delta != null ? round(greeks.delta, 4) : blankValue,
                gamma: greeks.gamma != null ? round(greeks.gamma, 4) : blankValue,
                theta: greeks.theta != null ? round(greeks.theta, 4) : blankValue,
                vega: greeks.vega != null ? round(greeks.vega, 4) : blankValue,
                rho: greeks.rho != null ? round(greeks.rho, 4) : blankValue,
                greeksUpdated: blankValue
            };
        } else if (sourceData.source === 'polygonHistory') {
            values = {
                bid: chain.bid != null ? numberWithCommas(chain.bid, 2) : blankValue,
                bidSize: blankValue,
                mid: chain.mid != null ? numberWithCommas(chain.mid, 2) : blankValue,
                ask: chain.ask != null ? numberWithCommas(chain.ask, 2) : blankValue,
                askSize: blankValue,
                last: blankValue,
                change: blankValue,
                changePer: blankValue,
                open: blankValue,
                breakEven: blankValue,
                impliedVol: blankValue,
                volume: chain.volume != null ? numberWithCommas(chain.volume, 0) : blankValue,
                openInt: blankValue,
                delta: blankValue,
                gamma: blankValue,
                theta: blankValue,
                vega: blankValue,
                rho: blankValue,
                greeksUpdated: blankValue
            };
        } else {
            console.log('unknown source for renderChainRow:', sourceData.source);
            return null;
        }

        return (
            <React.Fragment>
                {columns.map((column) => (
                    <div className={classNames('chainValue', column.key)} key={column.key} style={{ width: column.width }}>{values[column.key]}</div>
                ))}
            </React.Fragment>
        );
    }

    render() {
        var { optionsDivSize = 300, sourceData } = this.props;
        var { dialogJson, selectedExpiration, selectedStrike } = this.state;

        var expirations = Object.keys(sourceData.expirations);

        // TODO: on click header, highlight column
        // TODO: add column on end with nocontext menu, option to show the full data for that row
        //           with a json viewer? (svs-util component?)

        var expirationClickGroups = [
            [
                { name: 'View Expiration JSON', onClick: (object) => this.setState({ dialogJson: object }) },
            ]
        ];

        var strikeClickGroups = [
            [
                { name: 'View Strike JSON', onClick: (object) => this.setState({ dialogJson: object }) },
            ]
        ];

        return (
            <div className='optionChainsContainer'>
                {dialogJson && (
                    <JsonViewerDialog close={() => this.setState({ dialogJson: null })} json={dialogJson} />
                )}
                {expirations.map((expiration) => (
                    <div className={classNames('expirationContainer', { open: selectedExpiration === expiration })} key={expiration}>
                        <NoContextMenu groups={expirationClickGroups} object={sourceData.expirations[expiration]}>
                            <div className='expirationDate' onClick={() => this.handleExpirationClick(expiration)}>
                                <div style={{ gridColumn: 'span 3' }}>{dateFormat(parseDate(expiration), 'ddd mmm dS yyyy')}</div>
                                <div>Calls</div>
                                <div>{sourceData.symbol}: {sourceData.expirations[expiration].price}</div>
                                <div>Puts</div>
                            </div>
                        </NoContextMenu>
                        <div className='optionChains' style={{ height: selectedExpiration === expiration ? optionsDivSize : 0 }}>
                            <div className='chainCall chainTitles'>
                                {this.renderChainRow()}
                            </div>
                            <div className='chainStrike chainTitles'>Strike</div>
                            <div className='chainPut chainTitles'>
                                {this.renderChainRow()}
                            </div>

                            <div className='chainCalls' onScroll={(event) => this.handleChainScroll(event)}>
                                {sourceData.expirations[expiration].strikes.map((strike, i) => (
                                    <NoContextMenu groups={strikeClickGroups} object={strike} key={strike.strike}>
                                        <div
                                            className={classNames(
                                                'chainCall',
                                                i % 2 === 0 ? 'evenRow' : 'oddRow',
                                                { inTheMoney: sourceData.expirations[expiration].price > strike.strike, selected: selectedStrike === strike.strike }
                                            )}
                                            onClick={() => this.setState({ selectedStrike: strike.strike })}
                                        >
                                            {this.renderChainRow(strike.call)}
                                        </div>
                                    </NoContextMenu>
                                ))}
                            </div>
                            <div className='chainStrikes' onScroll={(event) => this.handleChainScroll(event)}>
                                {sourceData.expirations[expiration].strikes.map((strike) => (
                                    <div className='chainStrike' key={strike.strike}>{strike.strike}</div>
                                ))}
                            </div>
                            <div className='chainPuts' onScroll={(event) => this.handleChainScroll(event)}>
                                {sourceData.expirations[expiration].strikes.map((strike, i) => (
                                    <NoContextMenu groups={strikeClickGroups} object={strike} key={strike.strike}>
                                        <div
                                            className={classNames(
                                                'chainPut',
                                                i % 2 === 0 ? 'evenRow' : 'oddRow',
                                                { inTheMoney: sourceData.expirations[expiration].price < strike.strike, selected: selectedStrike === strike.strike }
                                            )}
                                            onClick={() => this.setState({ selectedStrike: strike.strike })}
                                        >
                                            {this.renderChainRow(strike.put)}
                                        </div>
                                    </NoContextMenu>
                                ))}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        );
    }
}

export default OptionChains;
