import React, { Component } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// import classNames from 'classnames';
import dateFormat from 'dateformat';
import { Chart } from 'react-charts';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, TimeScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import 'chartjs-adapter-date-fns';

import { ajax, getLocalCeiling, getLocalFloor, parseDate, numberWithCommas, round } from 'svs-utils/web';
import { JsonViewerDialog, Input } from 'svs-utils/react';

import { RanTestsTable, TradesTable, YearlySummaryTable } from '../../../tables/tables.js';

import './viewTest.scss';

ChartJS.register(TimeScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

class ViewTest extends Component {

    constructor(props) {
        super(props);

        this.state = {
            dialogJson: null,
            myTest: null,
            errorMessage: '',
            lastUpdated: null,
            chartType: 'chartJs',
            tradesTableOpen: false,
        };
    }

    componentDidMount() {
        this.getTests();
    }

    mapFunc(test) {
        var newTest = {
            ...test,
            params: {
                ...test.params,
                startDate: parseDate(test.params.startDate),
                endDate: parseDate(test.params.endDate),
            },
            startDate: parseDate(test.startDate),
            endDate: parseDate(test.endDate),
            testStarted: parseDate(test.testStarted),
            testEnded: parseDate(test.testEnded),
            tradeResults: test.tradeResults?.map((result) => ({
                ...result,
                dateFilled: parseDate(result.dateFilled),
                dateClosed: parseDate(result.dateClosed),
                expiration: parseDate(result.expiration),
                tradeDate: new Date(parseDate(result.dateClosed).setHours(0, 0, 0, 0)),
                endingBalance: result.balance + result.profit,
                endingProfit: round((result.balance + result.profit) - (test.startingBalance || test.params.cash), 2),
                endingProfitPercent: round(((result.balance + result.profit) - (test.startingBalance || test.params.cash)) / (test.startingBalance || test.params.cash) * 100, 1),
            })),
            resultsSummary: test.resultsSummary?.map((result) => ({
                ...result,
                startDate: parseDate(result.startDate),
                endDate: parseDate(result.endDate),
                year: result.year === '0000' ? 'All years' : result.year,
            })),
        };

        var endingBalances = newTest.tradeResults.map((result) => result.endingBalance);
        newTest.maxEndingBalance = Math.max(...endingBalances);
        newTest.minEndingBalance = Math.min(...endingBalances);

        var profits = newTest.tradeResults.map((result) => result.endingProfit);
        newTest.maxProfit = Math.max(...profits);
        newTest.minProfit = Math.min(...profits);

        var profitPercents = newTest.tradeResults.map((result) => result.endingProfitPercent);
        newTest.maxProfitPercent = Math.max(...profitPercents);
        newTest.minProfitPercent = Math.min(-100, ...profitPercents);

        return newTest;
    }

    async getTests() {
        var { urlParams: { homePath2 } } = this.props;

        var results = null;
        var myTest = null;
        if (/^[a-f0-9]{32}$/.test(homePath2)) {
            results = await ajax({ endPoint: '/getMyTest', data: { hash: homePath2 } });
            if (results.result) {
                myTest = this.mapFunc(results.myTest);

                this.setState({ myTest, lastUpdated: new Date() });
            } else {
                this.setState({ errorMessage: results.desc });
            }
        } else if (/^[0-9]{1,2}$/.test(homePath2)) {
            results = await ajax({ endPoint: '/getMyBotResults', data: { botId: homePath2 } });
            if (results.result) {
                myTest = this.mapFunc(results.myTest);

                this.setState({ myTest, lastUpdated: new Date() });
            } else {
                this.setState({ errorMessage: results.desc });
            }
        } else {
            this.setState({ errorMessage: 'Unknown results identifier' });
        }
    }

    render() {
        var { dialogJson, myTest, errorMessage, lastUpdated, chartType, tradesTableOpen } = this.state;

        var testInfoClickGroups = [
            [
                { name: 'View Params', onClick: (myTest) => this.setState({ dialogJson: myTest.params }) },
                { name: 'View Results JSON', onClick: (myTest) => this.setState({ dialogJson: myTest.results }) },
                { name: 'View Trades JSON', onClick: (myTest) => this.setState({ dialogJson: myTest.tradeResults }) },
                { name: 'View All Test Info', onClick: (myTest) => this.setState({ dialogJson: myTest }) },
            ],
        ];

        var testSummaryClickGroups = [
            [
                { name: 'View Summary JSON', onClick: (trade) => this.setState({ dialogJson: trade }) },
            ],
        ];

        var tradeInfoClickGroups = [
            [
                { name: 'View Trade JSON', onClick: (trade) => this.setState({ dialogJson: trade }) },
            ],
        ];

        var MyChartType = chartType === 'reactCharts' ? MyChart : MyChart2;
        var openJsonDialog = (json) => this.setState({ dialogJson: json });

        return (
            <React.Fragment>
                {dialogJson && (
                    <JsonViewerDialog close={() => this.setState({ dialogJson: null })} json={JSON.stringifySafe(dialogJson)} />
                )}
                <div className='tests'>
                    <div className='testsHeader'>
                        <div className='contentHeader'>{myTest?.name}</div>
                        <div className='lastUpdatedContainer'>{lastUpdated && `Last Updated: ${dateFormat(lastUpdated, 'mmm dS, yyyy "at" hh:MM:ss tt')}`}</div>
                    </div>
                    {errorMessage || (myTest && (
                        <React.Fragment>
                            <div className='myTableContainer homeBoxShadow'>
                                <RanTestsTable tests={[myTest]} clickGroups={testInfoClickGroups} showName={false} />
                            </div>
                            <div className='myTestSectionHeader'>Yearly Summary</div>
                            <div className='myTableContainer homeBoxShadow'>
                                <YearlySummaryTable years={myTest.resultsSummary} clickGroups={testSummaryClickGroups} />
                            </div>
                            <div className='myTestSectionHeader'>Chart</div>
                            <div className='myTestChartContainer homeBoxShadow'>
                                {/* <Input
                                    className='chartSelectBox'
                                    type='select'
                                    selectItems={[{ value: 'chartJs', label: 'ChartJS' }, { value: 'reactCharts', label: 'react-charts' }]}
                                    value={chartType}
                                    onChange={(value) => this.setState({ chartType: value })}
                                /> */}
                                <MyChartType myTest={myTest} openJsonDialog={openJsonDialog} />
                            </div>
                            <div className='myTestSectionHeader'>Trades</div>
                            <div className='myTableContainer homeBoxShadow'>
                                {tradesTableOpen ? (
                                    <React.Fragment>
                                        <Input type='button' label='Hide All Trades' onClick={() => this.setState({ tradesTableOpen: false })} />
                                        <TradesTable trades={myTest.tradeResults} clickGroups={tradeInfoClickGroups} />
                                    </React.Fragment>
                                ) : (
                                    <Input type='button' label='Show All Trades' onClick={() => this.setState({ tradesTableOpen: true })} />
                                )}
                            </div>
                        </React.Fragment>
                    ))}
                </div>
            </React.Fragment>
        );
    }
}

function MyChart(props) {
    var { myTest } = props;

    var primaryAxis = React.useMemo(
        () => ({
            getValue: (datum) => datum.date,
            scaleType: 'localTime',
        }),
        [],
    );

    var secondaryAxes = React.useMemo(
        () => [
            {
                getValue: (datum) => datum.endingBalance,
            },
        ],
        [],
    );

    if (!myTest.tradeResults?.length) {
        return <div>Unable to make chart</div>;
    }

    var data = [
        {
            label: 'Running Balance',
            data: myTest.tradeResults.map((trade) => ({
                date: trade.tradeDate,
                endingBalance: trade.endingBalance,
            }))
        },
    ];

    return (
        <div className='tradeResultsChartContainer'>
            <Chart className='tradeResultsChart' options={{ data, primaryAxis, secondaryAxes }} />
        </div>
    );
}

function MyChart2(props) {
    var { myTest, openJsonDialog } = props;

    if (!myTest.tradeResults?.length) {
        return <div>Unable to make chart</div>;
    }

    var minX = new Date(myTest.tradeResults[0].tradeDate.getTime());
    minX.setDate(minX.getDate() - 1);
    var maxX = new Date(myTest.tradeResults[myTest.tradeResults.length - 1].tradeDate.getTime());
    maxX.setDate(maxX.getDate() + 1);

    var colors = ['#88f', '#da3a37', '#33dc33', '#9c00e5', '#e1e100'];

    var data = {
        datasets: [
            // {
            //     label: 'Running Profit',
            //     data: myTest.tradeResults,
            //     borderColor: colors[0],
            //     backgroundColor: colors[0],
            //     parsing: { xAxisKey: 'tradeDate', yAxisKey: 'endingProfit' },
            //     pointRadius: 2,
            //     pointHitRadius: 3,
            //     pointHoverRadius: 3,
            // },
            {
                label: 'Running Profit %',
                data: myTest.tradeResults,
                borderColor: colors[0],
                backgroundColor: colors[0],
                parsing: { xAxisKey: 'tradeDate', yAxisKey: 'endingProfitPercent' },
                pointRadius: 2,
                pointHitRadius: 3,
                pointHoverRadius: 3,
                // yAxisID: 'yRight',
            },
        ],
    };

    var options = {
        // events: ['click', 'contextmenu'],
        onClick: (event, elements, chart) => openJsonDialog(elements[0].element.$context.raw),
        maintainAspectRatio: false,
        // elements: { point: { radius: 0 } },
        scales: {
            x: {
                type: 'time',
                min: minX,
                max: maxX,
                time: { unit: 'day', displayFormats: { day: 'MMM dd yy' } },
            },
            // y: {
            //     type: 'linear',
            //     // min: -(myTest.startingBalance || myTest.params.cash),
            //     min: Math.min(0, getLocalFloor(Math.min(...myTest.tradeResults.map((result) => result.endingProfit)))),
            //     max: getLocalCeiling(myTest.maxProfit),
            //     title: {
            //         display: true,
            //         text: 'Profit $',
            //     }
            // },
            y: { // yRight
                type: 'linear',
                // position: 'right', // `axis` is determined by the position as `'y'`
                // min: -100,
                min: Math.min(0, getLocalFloor(Math.min(...myTest.tradeResults.map((result) => result.endingProfitPercent)))),
                max: round(getLocalCeiling(myTest.maxProfit) / (myTest.startingBalance || myTest.params.cash) * 100, 2),
                title: {
                    display: true,
                    text: 'Profit %',
                }
            },
        },
        plugins: {
            tooltip: {
                intersect: false,
                mode: 'nearest',
                axis: 'x',
                position: 'nearest',
                callbacks: {
                    title: (elements) => dateFormat(elements[0].raw.tradeDate, 'ddd mmm d, yyyy'),
                    label: (element) => {
                        var row = element.raw;
                        return `Day: ${row.profitPercent < 0 ? '' : '+'}${row.profitPercent.toFixed(1)}% (${row.profit < 0 ? '-' : '+'}$${numberWithCommas(Math.abs(row.profit), 2)}), Total: ${row.endingProfitPercent < 0 ? '' : '+'}${row.endingProfitPercent}% (${row.endingProfit < 0 ? '-' : '+'}$${numberWithCommas(Math.abs(row.endingProfit), 2)})`;
                    },
                },
            },
        },
        hover: { intersect: false, mode: 'x' }, // Show all points on x-axis
    };

    return (
        <div className='tradeResultsChartContainer'>
            <Line options={options} data={data} />
        </div>
    );
}

export default function ViewTestWrapper(props) {
    var navigate = useNavigate();
    var urlParams = useParams();

    return <ViewTest {...props} navigate={navigate} urlParams={urlParams} />;
}
