import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
// import classNames from 'classnames';
import dateFormat from 'dateformat';

import { ajax } from 'svs-utils/web';
import { Dialog, Input, Table2 } from 'svs-utils/react';

import './webUsers.scss';

class WebUsers extends Component {

    constructor(props) {
        super(props);

        this.state = {
            dialogType: null,
            dialogValues: {},
            editingUserId: null,
            errorMessage: '',
            lastUpdated: null,
            users: [],
        };
    }

    componentDidMount() {
        this.getWebsiteUsers();
    }

    async getWebsiteUsers() {
        var results = await ajax({ endPoint: '/getWebsiteUsers' });
        if (results.result) {
            this.setState({ users: results.users, lastUpdated: new Date() });
        } else {
            this.setState({ errorMessage: results.desc });
        }
    }

    getDialogContent() {
        var { dialogType, dialogValues } = this.state;

        var close = () => this.setState({ dialogType: null, dialogValues: {}, editingUserId: null });
        var defaultProps = { className: 'dialogEditUser', close, width: 400 };
        if (dialogType === 'changeUsername') {
            return (
                <Dialog {...defaultProps} header='Change Username' height={150}>
                    <Input label='Username' value={dialogValues.username} onChange={(value) => this.handleInputChange('username', value)} />
                    <Input type='button' label='Submit' onClick={() => this.handleSubmitChangeUsername()} />
                </Dialog>
            );
        } else if (dialogType === 'changePassword') {
            return (
                <Dialog {...defaultProps} header='Change Password' height={275}>
                    <Input label='Current Password' value={dialogValues.currentPassword || ''} onChange={(value) => this.handleInputChange('currentPassword', value)} />
                    <Input label='New Password' value={dialogValues.newPassword || ''} onChange={(value) => this.handleInputChange('newPassword', value)} />
                    <Input label='New Password (again)' value={dialogValues.newPassword2 || ''} onChange={(value) => this.handleInputChange('newPassword2', value)} />
                    <Input type='button' label='Submit' onClick={() => this.handleSubmitChangePassword()} />
                </Dialog>
            );
        }
    }

    handleInputChange(key, value) {
        var { dialogValues } = this.state;
        this.setState({ dialogValues: { ...dialogValues, [key]: value } });
    }

    async handleSubmitChangeUsername() {
        var { dialogType, dialogValues, editingUserId, users } = this.state;

        if (dialogType !== 'changeUsername') {
            return;
        }

        if (!dialogValues.username) {
            return alert('A new username is required');
        }

        var results = await ajax({ endPoint: '/editUserUsername', data: { userId: editingUserId, username: dialogValues.username } });
        if (results.result) {
            var user = users.find((user) => user.id === editingUserId);
            user.username = dialogValues.username;
            this.setState({ dialogType: null, dialogValues: {}, editingUserId: null, users });
        } else {
            alert(results.desc);
        }
    }

    async handleSubmitChangePassword() {
        var { dialogType, dialogValues, editingUserId } = this.state;

        if (dialogType !== 'changePassword') {
            return;
        }

        if (!dialogValues.currentPassword || !dialogValues.newPassword || !dialogValues.newPassword2) {
            return alert('All fields are required.');
        }
        if (dialogValues.newPassword !== dialogValues.newPassword2) {
            return alert('Your new passwords do not match.');
        }

        var results = await ajax({ endPoint: '/editUserPassword', data: { userId: editingUserId, ...dialogValues } });
        if (results.result) {
            this.setState({ dialogType: null, dialogValues: {}, editingUserId: null });
        } else {
            alert(results.desc);
        }
    }

    render() {
        var { brokerageAccounts } = this.props;
        var { errorMessage, lastUpdated, users } = this.state;

        var dialogContent = this.getDialogContent();

        var usersTableHeaders = [
            { key: 'accountType', label: 'Type', dataType: 'string', width: 100 },
            { key: 'username', label: 'Username', dataType: 'string', width: 200 },
            { key: 'accountsAccess', label: 'Accounts Access', dataType: 'string', width: 400 },
        ];
        var usersTableRows = users.map((user) => ({
            ...user,
            accountsAccess: (user.userInfo.accountsAccess || []).map((id) => brokerageAccounts[id]?.name || id).join(', '),
            rowId: user.id,
            noContextObject: user,
        }));

        var botClickGroups = [
            [
                { name: 'Change Username', onClick: (user) => this.setState({ dialogType: 'changeUsername', dialogValues: { username: user.username }, editingUserId: user.id }) },
                { name: 'Change Password', onClick: (user) => this.setState({ dialogType: 'changePassword', editingUserId: user.id }) },
            ]
        ];

        return (
            <div className='users'>
                {dialogContent}
                <div className='usersHeader'>
                    <div className='contentHeader'>Website Users</div>
                    <div className='lastUpdatedContainer'>{lastUpdated && `Last Updated: ${dateFormat(lastUpdated, 'mmm dS, yyyy "at" hh:MM:ss tt')}`}</div>
                </div>
                {errorMessage || (
                    <div className='myTableContainer homeBoxShadow'>
                        <Table2 headers={usersTableHeaders} rows={usersTableRows} clickGroups={botClickGroups} alternateColors={true} />
                    </div>
                )}
            </div>
        );
    }
}

export default WebUsers;
