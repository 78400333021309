import React, { Component } from 'react';
// import dateFormat from 'dateformat';
import classNames from 'classnames';

import { parseDate } from 'svs-utils/web';
import { Dialog, Input, Section, useAppLayout } from 'svs-utils/react';

import { checkCreditSpreadParams, checkIronButterflyParams } from '../../../utils/strategyUtils.js';

import './bot-settings.scss';

class BotSettingsDialog extends Component {
    constructor(props) {
        super(props);

        var getSpreadsObject = () => ({
            startDate: parseDate('2022-02-23 00:00:00-05:00'),
            endDate: new Date(),
            cash: 1000,
            balanceOffset: 0,
            use: 'all',
            symbol: 'SPXW',
            entryType: 'percentGoalProfit',
            startTime: '14:00',
            approvedDays: [1, 3, 5],
            distanceFromCurrentPrice: 50,
            minPremiumPerCondor: 19,
            skipFedNewsDays: true,
            slippage: 0,
            exitThresholdType: 'percent',
            exitThreshold: 0.001,
            exitThresholdEodIncrease: true,
            shouldTakeProfit: false,
            takeProfit: 1,
        });

        this.state = {
            strategy: 'creditSpreads',

            creditSpreads: {
                ...getSpreadsObject(),
                goalProfitPercent: 0.01,
                maxSpread: 5,
                forceExactSpread: false,
            },
            ironButterflies: {
                ...getSpreadsObject(),
                entryType: 'fixedWidth',
            },
        };
    }

    componentDidMount() {
        var { editParams = {} } = this.props;
        if (editParams?.botId) {
            this.setInitialState(editParams);
        }
    }

    componentDidUpdate(prevProps) {
        var { editParams } = this.props;
        if (editParams?.botId && prevProps.editParams?.botId !== editParams.botId) {
            this.setInitialState(editParams);
        }
    }

    setInitialState(editParams = {}) {
        if (!editParams.strategy) {
            return;
        }
        if (!this.state[editParams.strategy]) {
            return;
        }

        var {
            startDate, endDate, cash, use, symbol, maxSpread, entryType, forceExactSpread, startTime, approvedDays, goalProfitPercent,
            distanceFromCurrentPrice, minPremiumPerCondor, balanceOffset, skipFedNewsDays, exitThresholdEodIncrease, slippage,
            exitThresholdType, exitThreshold, shouldTakeProfit, takeProfit,
        } = this.state[editParams.strategy];

        this.setState({
            strategy: editParams.strategy,
            [editParams.strategy]: {
                ...this.state[editParams.strategy],
                startDate: editParams.startDate || startDate,
                endDate: editParams.endDate || endDate,
                cash: editParams.cash || cash,
                balanceOffset: editParams.balanceOffset || balanceOffset,
                use: editParams.use || use,
                symbol: editParams.symbol || symbol,
                maxSpread: editParams.maxSpread || maxSpread,
                entryType: editParams.entryType || entryType,
                forceExactSpread: editParams.forceExactSpread || forceExactSpread,
                startTime: editParams.startTime || startTime,
                approvedDays: editParams.approvedDays || approvedDays,
                goalProfitPercent: editParams.goalProfitPercent || goalProfitPercent,
                distanceFromCurrentPrice: editParams.distanceFromCurrentPrice || distanceFromCurrentPrice,
                minPremiumPerCondor: editParams.minPremiumPerCondor || minPremiumPerCondor,
                skipFedNewsDays: typeof editParams.skipFedNewsDays === 'boolean' ? editParams.skipFedNewsDays : skipFedNewsDays,
                slippage: editParams.slippage || slippage,
                exitThresholdType: editParams.exitThresholdType || exitThresholdType,
                exitThreshold: editParams.exitThreshold || exitThreshold,
                exitThresholdEodIncrease: typeof editParams.exitThresholdEodIncrease === 'boolean' ? editParams.exitThresholdEodIncrease : exitThresholdEodIncrease,
                shouldTakeProfit: editParams.shouldTakeProfit || shouldTakeProfit,
                takeProfit: editParams.takeProfit || takeProfit,
            },
        });
    }

    handleInputChange(key, value) {
        var { strategy } = this.state;
        this.setState({ [strategy]: { ...this.state[strategy], [key]: value } });
    }

    handleSubmitClick() {
        var { editParams = {}, forTesting = true, submit } = this.props;
        var { strategy } = this.state;

        var params = { strategy, ...this.state[strategy] };

        var ret = {};
        if (strategy === 'creditSpreads') {
            ret = checkCreditSpreadParams(params, forTesting);
        } else if (strategy === 'ironButterflies') {
            ret = checkIronButterflyParams(params, forTesting);
        } else {
            return;
        }
        if (!ret.result) {
            return alert(ret.desc);
        }

        submit(ret.params, editParams?.botId);
    }

    onEnter(event, func) {
        if (event.key === 'Enter') {
            func(true);
        }
    }

    render() {
        var { appLayout, close, editParams = {}, forTesting = true } = this.props;
        var { strategy } = this.state;
        var {
            startDate,
            endDate,
            cash,
            balanceOffset,
            use,
            symbol,
            maxSpread,
            entryType,
            forceExactSpread,
            startTime,
            approvedDays,
            goalProfitPercent,
            distanceFromCurrentPrice,
            minPremiumPerCondor,
            skipFedNewsDays,
            slippage,
            exitThresholdType,
            exitThreshold,
            exitThresholdEodIncrease,
            shouldTakeProfit,
            takeProfit,
        } = this.state[strategy];

        var isMobile = appLayout.isMobile || ['XS', 'S'].includes(appLayout.layoutSize);

        var fields = null;
        var strategyItems = [
            { value: 'creditSpreads', label: 'Credit Spreads' },
            { value: 'ironButterflies', label: 'Iron Butterfly' },
        ];
        var symbolItems = [
            { value: 'SPXW', label: 'SPXW' },
        ];
        var daysPickerItems = [
            { value: 1, label: 'Mon' },
            { value: 2, label: 'Tue' },
            { value: 3, label: 'Wed' },
            { value: 4, label: 'Thu' },
            { value: 5, label: 'Fri' },
        ];
        var slippageOptions = [
            { value: 0, label: 0 },
            { value: 5, label: 5 },
            { value: 10, label: 10 },
        ];
        var thresholdTypeOptions = [
            { value: 'percent', label: 'Percent' },
        ];
        var entryTypeOptions = [
            strategy === 'creditSpreads' ? { value: 'percentGoalProfit', label: 'Percent Goal Profit' } : null,
            { value: 'fixedWidth', label: 'Fixed Width' },
        ];
        entryTypeOptions = entryTypeOptions.filter((entry) => !!entry);

        var parseNumber = (value) => parseFloat(value) || (parseFloat(value) === 0 ? 0 : '');

        fields = (
            <React.Fragment>
                <div className='inputContainer'>
                    <Input label='Strategy' type='select' selectItems={strategyItems} value={strategy} onChange={(strategy) => this.setState({ strategy })} />
                    <Input label='Symbol' type='select' selectItems={symbolItems} value={symbol} onChange={(value) => this.handleInputChange('symbol', value)} />
                </div>
                <Section label='Account Information'>
                    <div className='inputContainer'>
                        {forTesting ? (
                            <Input type='number' label='Starting Cash' value={cash} onChange={(value) => this.handleInputChange('cash', parseNumber(value))} />
                        ) : (
                            <Input type='number' label='Balance Offset' value={balanceOffset} onChange={(value) => this.handleInputChange('balanceOffset', parseNumber(value))} />
                        )}
                        <Input
                            type='select'
                            label='Use Balance'
                            value={use}
                            selectItems={[{ label: 'All', value: 'all' }, { label: 'Half', value: 'half' }]}
                            onChange={(value) => this.handleInputChange('use', value)}
                        />
                    </div>
                </Section>
                <Section label='Run Times'>
                    {forTesting && (
                        <div className='inputContainer'>
                            <Input type='date' label='Start Date' value={startDate} onChange={(value) => this.handleInputChange('startDate', value)} />
                            <Input type='date' label='End Date' value={endDate} onChange={(value) => this.handleInputChange('endDate', value)} />
                        </div>
                    )}
                    <div className='inputContainer'>
                        <Input
                            type='pillPicker'
                            label='Approved Days'
                            value={approvedDays}
                            selectItems={daysPickerItems}
                            onChange={(value) => this.handleInputChange('approvedDays', value.sort((a, b) => a - b))}
                        />
                        <Input type='time' label='Start Time' value={startTime} onChange={(value) => this.handleInputChange('startTime', value)} />
                    </div>
                    <div className='inputContainer'>
                        <Input
                            type='checkbox'
                            label='Skip Fed News Days'
                            value={skipFedNewsDays}
                            onChange={(value) => this.handleInputChange('skipFedNewsDays', value)}
                        />
                    </div>
                </Section>
                {forTesting && (
                    <Section label='Test Options'>
                        <div className='inputContainer'>
                            <Input
                                type='select'
                                label='Slippage'
                                value={slippage}
                                selectItems={slippageOptions}
                                onChange={(value) => this.handleInputChange('slippage', parseNumber(value))}
                                tooltip='Add slippage per iron condor to the entry/exit limit prices to better simulate real life'
                            />
                        </div>
                    </Section>
                )}
                <Section label='Entry Parameters'>
                    <div className='inputContainer'>
                        <Input type='select' label='Entry Type' value={entryType} selectItems={entryTypeOptions} onChange={(value) => this.handleInputChange('entryType', value)} />
                        {entryType === 'percentGoalProfit' ? (
                            <Input type='percent' label='Goal Profit Percent' value={goalProfitPercent} onChange={(value) => this.handleInputChange('goalProfitPercent', parseNumber(value))} />
                        ) : (
                            <Input
                                type='number'
                                label='Distance from Current Price'
                                value={distanceFromCurrentPrice}
                                onChange={(value) => this.handleInputChange('distanceFromCurrentPrice', parseNumber(value))}
                            />
                        )}
                        {strategy === 'creditSpreads' && (
                            <React.Fragment>
                                <Input type='number' label='Max Spread' value={maxSpread} onChange={(value) => this.handleInputChange('maxSpread', parseNumber(value))} />
                                {entryType === 'percentGoalProfit' ? (
                                    <Input type='number' label='Min Premium per Condor' value={minPremiumPerCondor} onChange={(value) => this.handleInputChange('minPremiumPerCondor', parseNumber(value))} />
                                ) : (
                                    <div></div>
                                )}
                                <Input
                                    type='checkbox'
                                    label='Only Exact Spread'
                                    value={forceExactSpread}
                                    onChange={(value) => this.handleInputChange('forceExactSpread', value)}
                                    tooltip={'Force using the exact spread amount and dont allow spreads smaller than "Max Spread"'}
                                />
                            </React.Fragment>
                        )}
                    </div>
                </Section>
                <Section label='Exit Parameters'>
                    <div className='inputContainer'>
                        <Input
                            type='select'
                            label='Threshold Type'
                            value={exitThresholdType}
                            selectItems={thresholdTypeOptions}
                            onChange={(value) => this.handleInputChange('exitThresholdType', value)}
                            tooltip={'Percent: When the symbol is within a certan percent of the stikes\nDollar: When the symbol is within a fixed $ amount of the strikes\nPercent of Width: When the price is within a certain percent of the condor width'}
                        />
                        <Input
                            type='percent'
                            label='Threshold'
                            value={exitThreshold}
                            onChange={(value) => this.handleInputChange('exitThreshold', parseNumber(value))}
                            tooltip='The bot will close the iron condor when the symbol price gets within this amount of the strikes'
                        />
                        <Input
                            type='checkbox'
                            label='Double Threshold?'
                            value={exitThresholdEodIncrease}
                            onChange={(value) => this.handleInputChange('exitThresholdEodIncrease', value)}
                            tooltip={'Double the exit threshold in the last 5 minutes of the trading day'}
                        />
                    </div>
                </Section>
                <Section label='Take Profit Parameters'>
                    <div className='inputContainer'>
                        <Input
                            type='checkbox'
                            label='Take Profit'
                            value={shouldTakeProfit}
                            onChange={(value) => this.handleInputChange('shouldTakeProfit', value)}
                            style={{ margin: '20px 0px' }}
                            tooltip={'Close position early when we can take a percentage of the premium received'}
                        />
                        {shouldTakeProfit && (
                            <Input
                                type='percent'
                                label='Take Profit Amount'
                                value={takeProfit}
                                onChange={(value) => this.handleInputChange('takeProfit', parseNumber(value))}
                            />
                        )}
                    </div>
                </Section>
            </React.Fragment>
        );

        return (
            <Dialog
                className='dialogEditBot'
                close={close}
                header={forTesting ? 'Create New Test' : `Edit Bot - ${editParams.botName}`}
                height={510}
                width={750 + 15}
            >
                <div className={classNames('botSettingsContainer', { isMobile })}>
                    {fields}
                    <Input
                        type='button'
                        label='Submit'
                        onClick={() => this.handleSubmitClick()}
                    />
                </div>
            </Dialog>
        );
    }
}

export default function BotSettingsDialogWrapper(props) {
    let appLayout = useAppLayout();

    return <BotSettingsDialog {...props} appLayout={appLayout} />;
}
