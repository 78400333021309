import { parseDate } from 'svs-utils/web';

export function checkCreditSpreadParams(params, forTesting) {
    var ret = { result: false, desc: '' };
    params = { ...params };

    if (params.symbol !== 'SPXW') {
        ret.desc = 'Only SPXW is available for this strategy right now.';
        return ret;
    }

    // Account Information
    if (forTesting) {
        if (params.cash < 500) {
            ret.desc = 'Starting balance must be higher than 500.';
            return ret;
        }
    } else {
        delete params.cash;
        if (params.balanceOffset > 0) {
            ret.desc = 'Balance offset must be 0 or negative.';
            return ret;
        }
    }
    if (!['all', 'half'].includes(params.use)) {
        ret.desc = '"Use" must be either "All" or "Half".';
        return ret;
    }

    // Run Times
    if (forTesting) {
        if (params.startDate < parseDate('2020-01-01')) {
            ret.desc = 'Start Date must be on or after 1/1/2020';
            return ret;
        }
        params.startDate.setHours(0, 0, 0, 0);

        if (params.endDate < parseDate('2020-01-01') || params.endDate > new Date()) {
            ret.desc = 'End Date must be after 1/1/2020 and before today.';
            return ret;
        }
        params.endDate.setHours(23, 59, 59, 0);
    } else {
        delete params.startDate;
        delete params.endDate;
    }

    if (!params.approvedDays.length) {
        ret.desc = 'You need to select at least one Approved Day.';
        return ret;
    }
    for (var day of params.approvedDays) {
        if (![1, 2, 3, 4, 5].includes(day)) {
            ret.desc = 'Approved days must be in Monday through Friday.';
            return ret;
        }
    }
    params.approvedDays.sort((a, b) => a - b);

    var startTime = parseInt(params.startTime.replace(':', ''));
    if (isNaN(startTime) || startTime < 930 || startTime > 1530) {
        ret.desc = 'Start time must be between 9:30am and 3:30pm';
        return ret;
    }

    if (typeof params.skipFedNewsDays !== 'boolean') {
        ret.desc = 'Skip fed news days must be a boolean.';
        return ret;
    }

    // Test Options
    if (forTesting) {
        if (![0, 5, 10].includes(params.slippage)) {
            ret.desc = 'Slippage must be either 0, 5 or 10.';
            return ret;
        }
    } else {
        delete params.slippage;
    }

    // Entry Parameters
    if (!['percentGoalProfit', 'fixedWidth'].includes(params.entryType)) {
        ret.desc = 'Invalid Entry Type.';
        return ret;
    }

    params.maxSpread = parseInt(params.maxSpread)
    if (![5, 10, 15, 20, 25, 30].includes(params.maxSpread)) {
        ret.desc = 'Max Spread must be one of 5, 10, 15, 20, 25, 30.';
        return ret;
    }

    if (typeof params.forceExactSpread !== 'boolean') {
        ret.desc = 'Force Exact Spread must be a boolean.';
        return ret;
    }

    if (params.entryType === 'percentGoalProfit') {
        if (params.goalProfitPercent < 0.005 || params.goalProfitPercent > 0.5) {
            ret.desc = 'Trade Percent must be between 0.5% and 50%.';
            return ret;
        }

        if (params.minPremiumPerCondor < 0 || params.minPremiumPerCondor > 50) {
            ret.desc = 'Minimum Premium Per Condor must be between 0 and 50';
            return ret;
        }

        delete params.distanceFromCurrentPrice;
    } else if (params.entryType === 'fixedWidth') {
        if (params.distanceFromCurrentPrice < 10 || params.distanceFromCurrentPrice > 500) {
            ret.desc = 'Distance from Current Price must be between 10 and 500';
            return ret;
        }

        delete params.goalProfitPercent;
        delete params.minPremiumPerCondor;
    } else {
        ret.desc = 'Cant validate entry type params';
        return ret;
    }

    // Exit Parameters
    if (!['percent'].includes(params.exitThresholdType)) {
        ret.desc = 'Exit threshold type must be either "percent".';
        return ret;
    }

    if (params.exitThreshold < -0.1 || params.exitThreshold > 0.1) {
        ret.desc = 'Exit threshold must be between -10 and 10 (inclusive).';
        return ret;
    }

    if (typeof params.exitThresholdEodIncrease !== 'boolean') {
        ret.desc = 'Exit threshold eod increase must be a boolean.';
        return ret;
    }

    // Take Profit Parameters
    if (typeof params.shouldTakeProfit !== 'boolean') {
        ret.desc = 'Should take profit must be a boolean.';
        return ret;
    }

    if (params.takeProfit < 0 || params.takeProfit > 100) {
        ret.desc = 'Take profit must be between 0 and 100.';
        return ret;
    }

    delete params.strategyInfo;
    delete params.running;
    delete params.botId;
    delete params.botName;

    ret.params = params;
    ret.result = true;

    return ret;
}

export function checkIronButterflyParams(params, forTesting) {
    var ret = { result: false, desc: '' };
    params = { ...params };

    if (params.symbol !== 'SPXW') {
        ret.desc = 'Only SPXW is available for this strategy right now.';
        return ret;
    }

    // Account Information
    if (forTesting) {
        if (params.cash < 500) {
            ret.desc = 'Starting balance must be higher than 500.';
            return ret;
        }
    } else {
        delete params.cash;
        if (params.balanceOffset > 0) {
            ret.desc = 'Balance offset must be 0 or negative.';
            return ret;
        }
    }
    if (!['all', 'half'].includes(params.use)) {
        ret.desc = '"Use" must be either "All" or "Half".';
        return ret;
    }

    // Run Times
    if (forTesting) {
        if (params.startDate < parseDate('2020-01-01')) {
            ret.desc = 'Start Date must be on or after 1/1/2020';
            return ret;
        }
        params.startDate.setHours(0, 0, 0, 0);

        if (params.endDate < parseDate('2020-01-01') || params.endDate > new Date()) {
            ret.desc = 'End Date must be after 1/1/2020 and before today.';
            return ret;
        }
        params.endDate.setHours(23, 59, 59, 0);
    } else {
        delete params.startDate;
        delete params.endDate;
    }

    if (!params.approvedDays.length) {
        ret.desc = 'You need to select at least one Approved Day.';
        return ret;
    }
    for (var day of params.approvedDays) {
        if (![1, 2, 3, 4, 5].includes(day)) {
            ret.desc = 'Approved days must be in Monday through Friday.';
            return ret;
        }
    }
    params.approvedDays.sort((a, b) => a - b);

    var startTime = parseInt(params.startTime.replace(':', ''));
    if (isNaN(startTime) || startTime < 930 || startTime > 1530) {
        ret.desc = 'Start time must be between 9:30am and 3:30pm';
        return ret;
    }

    if (typeof params.skipFedNewsDays !== 'boolean') {
        ret.desc = 'Skip fed news days must be a boolean.';
        return ret;
    }

    // Test Options
    if (forTesting) {
        if (![0, 5, 10].includes(params.slippage)) {
            ret.desc = 'Slippage must be either 0, 5 or 10.';
            return ret;
        }
    } else {
        delete params.slippage;
    }

    // Entry Parameters
    if (!['fixedWidth'].includes(params.entryType)) {
        ret.desc = 'Invalid Entry Type.';
        return ret;
    }

    // fixedWidth
    if (params.distanceFromCurrentPrice < 1 || params.distanceFromCurrentPrice > 500) {
        ret.desc = 'Distance from Current Price must be between 1 and 500';
        return ret;
    }
    delete params.goalProfitPercent;
    delete params.minPremiumPerCondor;

    // Exit Parameters
    if (!['percent'].includes(params.exitThresholdType)) {
        ret.desc = 'Exit threshold type must be either "percent".';
        return ret;
    }

    if (params.exitThreshold < -0.1 || params.exitThreshold > 0.1) {
        ret.desc = 'Exit threshold must be between -10 and 10 (inclusive).';
        return ret;
    }

    if (typeof params.exitThresholdEodIncrease !== 'boolean') {
        ret.desc = 'Exit threshold eod increase must be a boolean.';
        return ret;
    }

    // Take Profit Parameters
    if (typeof params.shouldTakeProfit !== 'boolean') {
        ret.desc = 'Should take profit must be a boolean.';
        return ret;
    }

    if (params.takeProfit < 0 || params.takeProfit > 100) {
        ret.desc = 'Take profit must be between 0 and 100.';
        return ret;
    }

    delete params.strategyInfo;
    delete params.running;
    delete params.botId;
    delete params.botName;

    ret.params = params;
    ret.result = true;

    return ret;
}
