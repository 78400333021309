import React from 'react';

import { getGlobalStateUtils } from 'svs-utils/react';

var initialGlobalState = {
    runTests: {
    },
    viewSavedData: {
        data: {},
        dataViews: [{
            selectedDate: new Date(),
            selectedSource: null,
            selectedSymbol: 'SPXW',
            selectedOptionsDivSize: 300,
        }],
    }
};

export var { GlobalStateProvider, useStateSlice } = getGlobalStateUtils(React, initialGlobalState);
