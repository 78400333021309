import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import dateFormat from 'dateformat';
import stringify from 'json-stringify-safe';

import { LoginProvider } from 'svs-utils/react';

import { GlobalStateProvider } from './utils/reactUtils.js';
import AppRouter from './router.js';
import reportWebVitals from './reportWebVitals.js';

import './index.scss';

JSON.stringifySafe = stringify;
Date.prototype.toJSON = function () { return dateFormat(this, `yyyy-mm-dd HH:MM:ss.lp`); }; // eslint-disable-line no-extend-native

let skipLoginPaths = [
    '/etrade/',
    '/tdameritrade/',
];

var root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <GlobalStateProvider>
                <LoginProvider skipLoginPaths={skipLoginPaths}>
                    <AppRouter />
                </LoginProvider>
            </GlobalStateProvider>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
