import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import classNames from 'classnames';
import dateFormat from 'dateformat';

import { ajax } from 'svs-utils/web';
import { Input, JsonViewer, useAppLayout } from 'svs-utils/react';

import './apiCalls.scss';

class ApiCalls extends Component {

    constructor(props) {
        super(props);

        this.accounts = [
            { value: 1, label: 'Steven TD', brokerage: 'tdAmeritrade' },
            { value: 2, label: 'Steven ETrade', brokerage: 'eTrade' },
            { value: 3, label: 'Shared', brokerage: 'tradier' },
            { value: 4, label: 'Ryan', brokerage: 'tradier' },
            { value: 5, label: 'Matt', brokerage: 'tradier' },
        ];

        this.urls = {
            tradier: 'api.tradier.com',
            tdAmeritrade: 'api.tdameritrade.com',
            eTrade: 'api.etrade.com',
        };

        this.apiCalls = {
            getAccountsList: {
                label: 'Get Accounts List',
                sources: {
                    tradier: { endPoint: '/v1/user/profile', method: 'GET', fields: [] },
                    tdAmeritrade: { endPoint: '/v1/accounts', method: 'GET', fields: [] },
                    eTrade: { endPoint: '/v1/accounts/list', method: 'GET', fields: [] },
                },
            },
            getBalances: {
                label: 'Get Balances',
                sources: {
                    tradier: { endPoint: '/v1/accounts/$accountId/balances', method: 'GET', fields: [] },
                    tdAmeritrade: { endPoint: '/v1/accounts/$accountId', method: 'GET', fields: [{ param: 'fields', label: 'Fields', type: 'text', default: 'positions,orders' }] },
                    eTrade: { endPoint: '/v1/accounts/$accountId/balances', method: 'GET', fields: [] },
                },
            },
            getQuote: {
                label: 'Get Quote',
                sources: {
                    tradier: { endPoint: '/v1/markets/quotes', method: 'GET', fields: [{ param: 'symbols', label: 'Symbols', type: 'text' }] },
                    tdAmeritrade: { endPoint: '/v1/marketdata/quotes', method: 'GET', fields: [] },
                    eTrade: { endPoint: '/v1/markets/quotes', method: 'GET', fields: [] },
                },
            },
        };

        this.state = {
            currentTab: 'getAccountsList',
        };
        this.state.apiBrokerages = Object.entries(this.apiCalls).reduce((acc1, [key, info]) => ({
            ...acc1,
            [key]: {
                currentAccount: null,
                currentSource: 'tradier',
                sources: Object.entries(info.sources).reduce((acc2, [source, sourceInfo]) => ({
                    ...acc2,
                    [source]: {
                        fields: sourceInfo.fields.reduce((acc3, field) => ({ ...acc3, [field.param]: (field.default || '') }), {}),
                        results: 'Not sent yet',
                        lastFetched: null
                    },
                }), {}),
            }
        }), {});

        this.tabGroups = [
            {
                name: 'Brokerages',
                tabs: Object.entries(this.apiCalls).map(([key, info]) => ({ value: key, label: info.label, content: () => this.renderBrokerageApiCall(key) })),
            },
            {
                name: 'Data Sources',
                tabs: [
                    { value: 'one2', label: 'One', content: () => <div>Content for One 2</div> },
                    { value: 'two2', label: 'Two', content: () => <div>Content for Two 2</div> },
                    { value: 'three2', label: 'Three', content: () => <div>Content for Three 2</div> },
                ],
            },
        ];
    }

    async sendBrokerageApiCall(apiCallName) {
        var { apiBrokerages } = this.state;

        var apiCall = this.apiCalls[apiCallName]
        var api = apiBrokerages[apiCallName];
        var apiBrokerage = api.sources[api.currentSource];

        var accounts = this.accounts.filter((account) => account.brokerage === api.currentSource);
        var account = api.currentAccount || accounts[0].value

        var data = {
            account,
            source: api.currentSource,
            endPoint: apiCall.sources[api.currentSource].endPoint,
            method: apiCall.sources[api.currentSource].method,
            fields: apiBrokerage.fields,
        };
        var results = await ajax({ endPoint: '/sendApiCall', data });

        apiBrokerages = this.state.apiBrokerages;
        if (results.result) {
            apiBrokerages[apiCallName].sources[api.currentSource].results = results.data;
            apiBrokerages[apiCallName].sources[api.currentSource].lastFetched = new Date();
        } else {
            apiBrokerages[apiCallName].sources[api.currentSource].results = results.desc;
            apiBrokerages[apiCallName].sources[api.currentSource].lastFetched = new Date();
        }
        this.setState({ apiBrokerages });
    }

    renderBrokerageApiCall(apiCallName) {
        var { appLayout } = this.props;
        var { apiBrokerages } = this.state;

        var getAccountsStateFunc = () => {
            return (value) => {
                var { apiBrokerages } = this.state;
                apiBrokerages[apiCallName].currentAccount = value;
                this.setState({ apiBrokerages });
            }
        };
        var getSourceStateFunc = () => {
            return (value) => {
                var { apiBrokerages } = this.state;
                apiBrokerages[apiCallName].currentSource = value;
                this.setState({ apiBrokerages });
            }
        };
        var getStateFieldFunc = (key) => {
            return (value) => {
                var { apiBrokerages } = this.state;
                apiBrokerages[apiCallName].sources[apiBrokerages[apiCallName].currentSource].fields[key] = value;
                this.setState({ apiBrokerages });
            }
        };

        var apiCall = this.apiCalls[apiCallName];
        var api = apiBrokerages[apiCallName];

        var accounts = this.accounts.filter((account) => account.brokerage === api.currentSource);

        var sources = [
            { value: 'tradier', label: 'Tradier' },
            { value: 'tdAmeritrade', label: 'TD Ameritrade' },
            { value: 'eTrade', label: 'ETrade' },
        ];

        var lastFetched = api.sources[api.currentSource].lastFetched;

        var smallLayout = appLayout.isMobile || ['XS', 'S'].includes(appLayout.layoutSize);

        return (
            <div className='apiContainer'>
                <div className='header'>{apiCall.label} - https://{this.urls[api.currentSource]} {apiCall.sources[api.currentSource].endPoint}</div>
                <div className={classNames('apiCall', { smallLayout })}>
                    <div className='apiFields'>
                        <Input label='Account' type='select' selectItems={accounts} value={api.currentAccount || accounts[0].value} onChange={getAccountsStateFunc()} />
                        <Input label='Source' type='select' selectItems={sources} value={api.currentSource} onChange={getSourceStateFunc()} />
                        {apiCall.sources[api.currentSource].fields.map((field) => (
                            <Input
                                label={field.label}
                                type={field.type}
                                key={field.param}
                                value={api.sources[api.currentSource].fields[field.param]}
                                onChange={getStateFieldFunc(field.param)}
                            />
                        ))}
                        <Input label='Send' type='button' onClick={() => this.sendBrokerageApiCall(apiCallName)} />
                    </div>
                    <div className='apiResults'>
                        <div>{lastFetched ? `Last Fetched: ${dateFormat(lastFetched, 'ddd mmm d yyyy hh:MM:ss.ltt')}` : ''}</div>
                        <JsonViewer json={api.sources[api.currentSource].results} />
                    </div>
                </div>
            </div>
        );
    }

    render() {
        var { appLayout } = this.props;
        var { currentTab } = this.state;

        var smallLayout = appLayout.isMobile || ['XS', 'S'].includes(appLayout.layoutSize);

        var allTabs = this.tabGroups.reduce((acc, tabGroup) => [...acc, ...tabGroup.tabs], []);
        var selectedTab = allTabs.find((tab) => tab.value === currentTab);

        return (
            <div className='apiCallsContainer'>
                <div className='apiCallsHeader'>
                    <div className='contentHeader'>API Calls</div>
                </div>
                <div className={classNames('apiCallsContent', { smallLayout })}>
                    <div>
                        {this.tabGroups.map((tabGroup) => (
                            <div className='apiCallsTabGroup' key={tabGroup.name}>
                                <div className='tabGroupHeader'>{tabGroup.name}</div>
                                <div className='actionsList homeBoxShadow'>
                                    {tabGroup.tabs.map((tab) => (
                                        <div
                                            className={classNames({ selected: currentTab === tab.value })}
                                            key={tab.value}
                                            onClick={() => this.setState({ currentTab: tab.value })}
                                        >
                                            {tab.label}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className='apiCallsActionContent homeBoxShadow'>{selectedTab.content()}</div>
                </div>
            </div>
        );
    }
}

export default function ApiCallsWrapper(props) {
    let appLayout = useAppLayout();

    return <ApiCalls {...props} appLayout={appLayout} />;
}
