import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
// import classNames from 'classnames';
import dateFormat from 'dateformat';

import { ajax, parseDate } from 'svs-utils/web';
import { Table2 } from 'svs-utils/react';

import './actionArchive.scss';

class ActionArchive extends Component {

    constructor(props) {
        super(props);

        this.state = {
            actions: [],
            errorMessage: '',
            lastUpdated: null,
        };
    }

    componentDidMount() {
        this.getActionsArchive();
    }

    async getActionsArchive() {
        var results = await ajax({ endPoint: '/getActionsArchive' });
        if (results.result) {
            var actions = results.actions.map((action) => ({
                ...action,
                date: parseDate(action.date),
            }))
            this.setState({ actions, lastUpdated: new Date() });
        } else {
            this.setState({ errorMessage: results.desc });
        }
    }

    render() {
        var { actions, errorMessage, lastUpdated } = this.state;

        var actionsTableHeaders = [
            { key: 'user', label: 'User', dataType: 'string', width: 100 },
            { key: 'date', label: 'Date', dataType: 'Date', width: 220, dateFormat: 'mmm dS, yyyy "at" hh:MM:ss tt' },
            { key: 'action', label: 'Action', dataType: 'string', width: 800 },
        ];

        var actionsTableRows = actions.map((action) => ({
            ...action,
            rowId: action.id,
            noContextObject: action,
        }));

        return (
            <div className='actions'>
                <div className='actionsHeader'>
                    <div className='contentHeader'>Actions Archive</div>
                    <div className='lastUpdatedContainer'>{lastUpdated && `Last Updated: ${dateFormat(lastUpdated, 'mmm dS, yyyy "at" hh:MM:ss tt')}`}</div>
                </div>
                {errorMessage || (
                    <div className='myTableContainer homeBoxShadow'>
                        <Table2 headers={actionsTableHeaders} rows={actionsTableRows} maxHeight={500} alternateColors={true} />
                    </div>
                )}
            </div>
        );
    }
}

export default ActionArchive;
