/* eslint-disable no-loop-func */
import React from 'react';
import classNames from 'classnames';
import dateFormat from 'dateformat';

import { ajax, parseDate } from 'svs-utils/web';
import { Input, useAppLayout } from 'svs-utils/react';

import { useStateSlice } from '../../../../utils/reactUtils.js';
import OptionChains from '../../../optionsChains/optionsChains.js';

import './viewSavedData.scss';

function ViewSavedData(props) {
    let [dataSlice, setDataSlice] = useStateSlice('viewSavedData');
    let { data, dataViews } = dataSlice;

    let appLayout = useAppLayout();

    let addNewDataView = () => {
        if (dataViews.length < 5) {
            dataViews.push({
                selectedDate: new Date(),
                selectedSource: null,
                selectedSymbol: 'SPXW',
                selectedOptionsDivSize: 300,
            });
            setDataSlice({ dataViews });
        }
    };

    let handleSelectedDateChange = (index, newValue) => {
        dataViews[index].selectedDate.setFullYear(newValue.getFullYear(), newValue.getMonth(), newValue.getDate());
        setDataSlice({ dataViews });
    };

    let handleSelectedTimeChange = (index, newValue) => {
        var parts = newValue.split(':').map((num) => parseInt(num));
        dataViews[index].selectedDate.setHours(parts[0], parts[1]);
        setDataSlice({ dataViews });
    };

    let handleSelectedSourceChange = (index, newValue) => {
        dataViews[index].selectedSource = newValue
        setDataSlice({ dataViews });
    };

    let handleSelectedSymbolChange = (index, newValue) => {
        dataViews[index].selectedSymbol = newValue
        setDataSlice({ dataViews });
    };

    let handleOptionsDivSizeChange = (index, newValue) => {
        dataViews[index].selectedOptionsDivSize = newValue
        setDataSlice({ dataViews });
    };

    let getData = async (symbol, date, index) => {
        dataViews[index].loading = true;
        setDataSlice({ dataViews });

        let results = await ajax({ endPoint: '/getSavedData', data: { symbol, date } });

        setDataSlice((currentSlice) => {
            let dataViews = currentSlice.dataViews;
            dataViews[index].loading = false;

            let { data } = currentSlice;
            if (!results.result) {
                data[`${symbol}-${date}`] = results.desc;
                return { data, dataViews };
            }

            var sources = [];
            var orderNumbers = {
                tradier: 5,
                tdAmeritrade: 4,
                eTrade: 3,
                polygonHistory: 2,
                barchartCsv: 1,
            };
            for (var row of results.data.options) {
                row.date = parseDate(row.date);
                row.expiration = parseDate(row.expiration);

                var source = sources.find((next) => next.source === row.source);
                if (!source) {
                    source = { symbol: row.symbol, source: row.source, expirations: {}, orderNumber: orderNumbers[row.source] || 0 };
                    sources.push(source);
                }

                var expirationDate = dateFormat(row.expiration, 'yyyy-mm-dd');
                if (source.expirations[expirationDate]) {
                    console.log('duplicate expiration for same source', row);
                    continue;
                }

                var calls = row.calls.reduce((acc, call) => ({ ...acc, [call.strike]: call }), {});
                var puts = row.puts.reduce((acc, put) => ({ ...acc, [put.strike]: put }), {});
                var strikes = [];
                for (var [strike, call] of Object.entries(calls)) {
                    strikes.push({ strike: parseFloat(strike), call, put: puts[strike] || {} });
                }
                strikes.sort((a, b) => b.strike - a.strike);

                source.expirations[expirationDate] = {
                    dbId: row.id,
                    price: row.price,
                    quote: row.fullQuote,
                    strikes,
                };
            }
            sources.sort((a, b) => b.orderNumber - a.orderNumber);
            data[`${symbol}-${date}`] = sources;

            return { data, dataViews };
        });
    };

    let renderDataView = (dataView, index) => {
        var { selectedDate, selectedSource, selectedSymbol, selectedOptionsDivSize } = dataView;

        var date = dateFormat(selectedDate, 'yyyy-mm-dd HH:MM');
        var selectedData = data[`${selectedSymbol}-${date}`];

        var sourceOptions = [
            { value: 'tradier', label: 'Tradier' },
            { value: 'tdAmeritrade', label: 'TD Ameritrade' },
            { value: 'eTrade', label: 'ETrade' },
            { value: 'polygonHistory', label: 'Polygon' },
            // { value: 'barchartCsv', label: 'barchartCsv' },
        ];

        var symbolOptions = [
            { value: 'SPXW', label: 'SPXW' },
            { value: 'SPY', label: 'SPY' },
            { value: 'QQQ', label: 'QQQ' },
            { value: 'AAPL', label: 'AAPL' },
            { value: 'TSLA', label: 'TSLA' },
        ];

        var optionsDivSizes = [
            { value: 100, label: 'XS' },
            { value: 200, label: 'S' },
            { value: 300, label: 'M' },
            { value: 400, label: 'L' },
            { value: 500, label: 'XL' },
        ];

        var selectedSourceData = null;
        if (typeof selectedData === 'object') {
            if (!sourceOptions.find((source) => source.value === selectedSource)) {
                selectedSource = sourceOptions[0].value;
            }

            selectedSourceData = selectedData.find((next) => next.source === selectedSource) || null;
        }

        return (
            <div className='savedDataContainer homeBoxShadow' key={index}>
                {dataView.loading && (
                    // create loading component
                    // prop random true/false for random animation or defualt
                    // from saved images and these examples
                    // planets: https://onextrapixel.com/fun-css-loading-animations/
                    // 3d circle: https://codepen.io/jkantner/pen/qBdBJxY
                    // https://github.com/steelkiwi/SlidingSquareLoaderView
                    <div className='loadingOverlay'>
                        <div className='loadingIcon'>Loading...</div>
                    </div>
                )}
                <Input
                    type='date'
                    label='Pick A Date'
                    value={selectedDate}
                    onChange={(value) => handleSelectedDateChange(index, value)}
                />
                <Input
                    type='time'
                    label='Pick A Time'
                    value={dateFormat(selectedDate, 'HH:MM')}
                    onChange={(value) => handleSelectedTimeChange(index, value)}
                />
                <Input type='select' label='Symbol' selectItems={symbolOptions} value={selectedSymbol} onChange={(value) => handleSelectedSymbolChange(index, value)} />
                <Input type='select' label='Source' selectItems={sourceOptions} value={selectedSource} onChange={(value) => handleSelectedSourceChange(index, value)} />
                <div></div>
                <Input
                    type='select'
                    label='Box Size'
                    selectItems={optionsDivSizes}
                    value={selectedOptionsDivSize}
                    onChange={(value) => handleOptionsDivSizeChange(index, value)}
                />
                <div className='optionsContainer'>
                    {selectedData == null ? (
                        <div className='getDataButtonContainer'>
                            <div></div>
                            <Input type='button' label='Get the Data' className='getDataButton' onClick={() => getData(selectedSymbol, date, index)} />
                            <div></div>
                        </div>
                    ) : (
                        selectedSourceData ?
                        <OptionChains sourceData={selectedSourceData} optionsDivSize={selectedOptionsDivSize} /> :
                        (typeof selectedData === 'string' ? selectedData : `Data not available for this source. Sources available: ${selectedData.map((next) => next.source).join(', ')}`)
                    )}
                </div>
            </div>
        );
    };

    var isSmall = appLayout.isMobile || ['XS', 'S', 'M'].includes(appLayout.layoutSize);

    return (
        <div className={classNames('savedData', { isSmall })}>
            <div className='savedDataHeader'>
                <div className='contentHeader'>Saved Data</div>
            </div>
            {dataViews.map((dataView, i) => renderDataView(dataView, i))}
            {dataViews.length < 5 && (
                <div className='addDataViewButtonContainer homeBoxShadow'>
                    <Input type='button' label='Add Another View' className='addDataViewButton' onClick={addNewDataView} />
                </div>
            )}
        </div>
    );
}

export default ViewSavedData;
