import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import dateFormat from 'dateformat';

import { ajax } from 'svs-utils/web';
import { createAlert, Table2, JsonViewerDialog } from 'svs-utils/react';

import { BotSettingsDialog, EditBotDialog } from '../../../dialog/dialogs.js';

import './bots.scss';

function Bots(props) {
    let [bots, setBots] = useState([]);
    let [editBotDialogOpen, setEditBotDialogOpen] = useState(false);
    let [editBotParams, setEditBotParams] = useState({});
    let [errorMessage, setErrorMessage] = useState('');
    let [lastUpdated, setLastUpdated] = useState(null);
    let [viewJsonDialog, setViewJsonDialog] = useState(null);

    var navigate = useNavigate();

    useEffect(() => {
        getBotsRunning();
    }, []);

    let getBotsRunning = async () => {
        let results = await ajax({ endPoint: '/getBotsRunning' });
        if (results.result) {
            setBots(results.bots);
            setLastUpdated(new Date());
        } else {
            setErrorMessage(results.desc);
        }
    };

    let handleRunningSwitchClick = async (newValue, botId) => {
        let bot = bots.find((b) => b.botId === botId);
        console.log(bot, newValue);
        let results = await ajax({ endPoint: '/turnBotOnOff', data: { botId: bot.botId, onOff: newValue ? 'Y' : 'N' } });
        if (results.result) {
            bot.running = newValue;
            setBots([...bots]);
        } else {
            createAlert(results.desc);
        }
    };

    let handleStrategyInfoSubmit = async (params, botId = null) => {
        console.log(params);

        let newParams = {};
        if (params.strategy === 'creditSpreads') {
            newParams = { ...params };
        } else if (params.strategy === 'ironButterflies') {
            return createAlert('Not ready for iron butterflies yet');
        } else if (params.strategy === 'storeOptionsData') {
            if (!params.symbols.length) {
                return createAlert('You need to select at least one symbol to collect data.');
            }
            newParams.symbols = params.symbols;
        } else if (params.strategy === 'storePriceData') {
            if (!params.minuteBarSymbols.length) {
                return createAlert('You need to select at least one minute bar symbol to collect data.');
            }
            newParams.minuteBarSymbols = params.minuteBarSymbols;

            if (!params.streamingSymbols.length) {
                return createAlert('You need to select at least one straming symbol to collect data.');
            }
            newParams.streamingSymbols = params.streamingSymbols;
        } else {
            return createAlert('unknown strategy', params.strategy);
        }
        newParams.strategy = params.strategy;

        var results = await ajax({ endPoint: '/editBotStrategyInfo', data: { botId, params: newParams } });
        if (results.result) {
            setEditBotDialogOpen(false);
            setEditBotParams({});
            getBotsRunning();
        } else {
            createAlert(results.desc);
        }
    };

    let navigateTo = (path, event = {}) => {
        if (event.metaKey || event.ctrlKey) {
            window.open(path, '_blank');
        } else {
            navigate(path);
        }
    };

    var botClickGroups = [
        [
            { name: 'Go To Full Results', onClick: (bot, event) => navigateTo(`/viewresults/${bot.botId}`, event), shouldShow: (bot) => bot.strategy === 'creditSpreads' },
        ],
        [
            { name: 'Edit', onClick: (bot) => { setEditBotDialogOpen(true); setEditBotParams({ ...bot, ...bot.strategyInfo }) }},
            { name: 'View Bot Info', onClick: (bot) => setViewJsonDialog(bot) },
        ],
    ];

    var daysOfWeek = { 1: 'M', 2: 'Tu', 3: 'W', 4: 'Th', 5: 'F' };
    var creditSpreadTableHeaders = [
        { key: 'onoff', label: 'On/Off', dataType: 'switch', width: 60, onChange: (newValue, bot) => handleRunningSwitchClick(newValue, bot.botId) },
        { key: 'botId', label: 'ID', dataType: 'number', className: 'centered', width: 40 },
        { key: 'botName', label: 'Name', dataType: 'string', width: 200 },
        { key: 'symbol', label: 'Symbol', dataType: 'string', width: 80 },
        { key: 'use', label: 'Use', dataType: 'string', className: 'centered', width: 80 },
        { key: 'goalProfitPercent', label: 'Goal Profit', dataType: 'number', className: 'centered', width: 80, format: 'simplePercent' },
        { key: 'maxSpread', label: 'Max Spread', dataType: 'number', className: 'centered', width: 100 },
        { key: 'daysOfWeek', label: 'Days of Week', dataType: 'string', className: 'centered', width: 100 },
        { key: 'startTime', label: 'Start Time', dataType: 'string', className: 'centered', width: 100 },
    ];
    var creditSpreadTableRows = bots.filter((bot) => bot.strategy === 'creditSpreads').map((bot) => ({
        ...bot,
        ...bot.strategyInfo,
        rowId: bot.botId,
        onoff: bot.running,
        daysOfWeek: bot.strategyInfo.approvedDays.map((d) => daysOfWeek[d] || d).join(' '),
        noContextObject: bot,
    }));

    var storePriceTableHeaders = [
        { key: 'onoff', label: 'On/Off', dataType: 'switch', width: 60, onChange: (newValue, bot) => handleRunningSwitchClick(newValue, bot.botId) },
        { key: 'botId', label: 'ID', dataType: 'number', className: 'centered', width: 40 },
        { key: 'botName', label: 'Name', dataType: 'string', width: 200 },
        { key: 'minuteBarSymbols', label: 'Minute Bar Symbols', dataType: 'string', width: 400 },
        { key: 'streamingSymbols', label: 'Streaming Symbols', dataType: 'string', width: 400 },
    ];
    var storePriceTableRows = bots.filter((bot) => bot.strategy === 'storePriceData').map((bot) => ({
        ...bot,
        minuteBarSymbols: JSON.stringifySafe(bot.strategyInfo.minuteBarSymbols),
        streamingSymbols: JSON.stringifySafe(bot.strategyInfo.streamingSymbols),
        rowId: bot.botId,
        onoff: bot.running,
        noContextObject: bot,
    }));

    var storeOptionsTableHeaders = [
        { key: 'onoff', label: 'On/Off', dataType: 'switch', width: 60, onChange: (newValue, bot) => handleRunningSwitchClick(newValue, bot.botId) },
        { key: 'botId', label: 'ID', dataType: 'number', className: 'centered', width: 40 },
        { key: 'botName', label: 'Name', dataType: 'string', width: 200 },
        { key: 'symbols', label: 'Symbols', dataType: 'string', width: 400 },
    ];
    var storeOptionsTableRows = bots.filter((bot) => bot.strategy === 'storeOptionsData').map((bot) => ({
        ...bot,
        symbols: JSON.stringifySafe(bot.strategyInfo.symbols),
        rowId: bot.botId,
        onoff: bot.running,
        noContextObject: bot,
    }));

    return (
        <React.Fragment>
            {viewJsonDialog && (
                <JsonViewerDialog label={viewJsonDialog.botName} json={viewJsonDialog} close={() => setViewJsonDialog(null)} />
            )}
            {editBotDialogOpen && (
                ['storeOptionsData', 'storePriceData'].includes(editBotParams.strategy) ? (
                    <EditBotDialog
                        close={() => { setEditBotDialogOpen(false); setEditBotParams({}); }}
                        submit={handleStrategyInfoSubmit}
                        editParams={editBotParams}
                        strategy={editBotParams.strategy}
                    />
                ) : (
                    <BotSettingsDialog
                        close={() => { setEditBotDialogOpen(false); setEditBotParams({}); }}
                        submit={handleStrategyInfoSubmit}
                        editParams={editBotParams}
                        strategy={editBotParams.strategy}
                        forTesting={false}
                    />
                )
            )}
            <div className='bots'>
                <div className='botsHeader'>
                    <div className='contentHeader'>Running Bots</div>
                    <div className='lastUpdatedContainer'>{lastUpdated && `Last Updated: ${dateFormat(lastUpdated, 'mmm dS, yyyy "at" hh:MM:ss tt')}`}</div>
                </div>
                {errorMessage || (
                    <React.Fragment>
                        <div className='botsSectionHeader'>Credit Spreads Bots</div>
                        <div className='botsChartContainer homeBoxShadow'>
                            {!!creditSpreadTableRows.length && <Table2 headers={creditSpreadTableHeaders} rows={creditSpreadTableRows} clickGroups={botClickGroups} alternateColors={true} />}
                        </div>
                        <div className='botsSectionHeader'>Store Price Data Bots</div>
                        <div className='botsChartContainer homeBoxShadow'>
                            {!!storePriceTableRows.length && <Table2 headers={storePriceTableHeaders} rows={storePriceTableRows} clickGroups={botClickGroups} alternateColors={true} />}
                        </div>
                        <div className='botsSectionHeader'>Store Options Data Bots</div>
                        <div className='botsChartContainer homeBoxShadow'>
                            {!!storeOptionsTableRows.length && <Table2 headers={storeOptionsTableHeaders} rows={storeOptionsTableRows} clickGroups={botClickGroups} alternateColors={true} />}
                        </div>
                    </React.Fragment>
                )}
            </div>
        </React.Fragment>
    );
}

export default Bots;
