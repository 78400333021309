import React from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';

// import { ajax, sleep } from 'svs-utils/web';
import { AppLayout } from 'svs-utils/react';

import { ETradeLogin, Home } from './components/index.js';

function AppRouter(props) {
    // TODO: incorporate this into main LoginProvider, doesn't need to be logged in if going to these links
    // var path = window.location.pathname;
    // if (path !== '/' && !path.startsWith('/etrade/') && !path.startsWith('/tdameritrade/')) {
    //     navigate('/');
    // }

    return (
        <Routes>
            <Route path='/' element={<AppLayout />}>
                <Route path='*' element={<div>404</div>} />
                <Route path='404' element={<div>404</div>} />
                <Route path='etrade/:hashId' element={<ETradeLogin type='ETrade' />} />
                <Route path='tdameritrade/:hashId' element={<ETradeLogin type='TDAmeritrade' />} />
                <Route path=':homePath' element={<Home />}>
                    <Route path=':homePath2' element={<Home />}></Route>
                </Route>
                <Route index element={<Navigate to='/bots' />} />
            </Route>
        </Routes>
    );
}

export default AppRouter;
