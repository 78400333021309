/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
// import dateFormat from 'dateformat';

import { ajax } from 'svs-utils/web';
import { useAppLayout, useLogin } from 'svs-utils/react';

import { ActionArchive, ApiCalls, Bots, CompareResults, EmergencyActions, LogsOverlay, RunTests, Trades, ViewTest, ViewSavedData, WebUsers } from './content/index.js';
import './home.scss';

function Home(props) {
    let appLayout = useAppLayout();
    let login = useLogin();
    let navigate = useNavigate();
    let urlParams = useParams();

    let { homePath } = urlParams;

    let [logsOpen, setLogsOpen] = useState(false);
    let [logsFullScreen, setLogsFullScreen] = useState(false);
    let [logsHeight, setLogsHeight] = useState(null);
    let [menuOpen, setMenuOpen] = useState(!appLayout.isMobile && !['XS', 'S'].includes(appLayout.layoutSize));
    let [brokerageAccounts, setBrokerageAccounts] = useState({});

    let menuItems = {
        bots: { title: 'Bots', shouldShow: () => true, content: () => <Bots /> },
        trades: { title: 'Trades', shouldShow: () => true, content: () => <Trades /> },
        tests: { title: 'Run Tests', shouldShow: () => true, content: () => <RunTests user={login.user} /> },
        viewsaveddata: { title: 'View Saved Data', shouldShow: () => true, content: () => <ViewSavedData /> },
        webusers: { title: 'Website Users', shouldShow: () => true, content: () => <WebUsers brokerageAccounts={brokerageAccounts} /> },

        // godmode tabs only for me
        godmodeplaceholder: { title: <span>&nbsp;</span>, shouldShow: () => login.user.isGodmode, content: () => <div>Placeholder to separate godmode stuff</div> },
        emergency: { title: 'Emergency Actions', shouldShow: () => login.user.isGodmode, content: () => <EmergencyActions /> },
        apicalls: { title: 'API Calls', shouldShow: () => login.user.isGodmode, content: () => <ApiCalls /> },
        archive: { title: 'Actions Archive', shouldShow: () => login.user.isGodmode, content: () => <ActionArchive /> },

        // only direct links can get to these (not in menu)
        viewresults: { title: 'View Results', content: () => <ViewTest />, onlyDirect: true },
        compareresults: { title: 'Compare Results', content: () => <CompareResults />, onlyDirect: true },
    };

    useEffect(() => {
        let path = homePath?.toLowerCase();

        if (!menuItems[path] || (!menuItems[path].shouldShow?.() && !menuItems[path].onlyDirect)) {
            navigate('/404');
            return;
        }

        getBrokerageAccounts();
    }, []);

    let getBrokerageAccounts = async () => {
        var results = await ajax({ endPoint: '/getBrokerageAccounts' });
        if (results.result) {
            setBrokerageAccounts(results.brokerageAccounts.reduce((acc, account) => ({ ...acc, [account.id]: account }), {}));
        }
    };

    homePath = homePath?.toLowerCase();

    return (
        <div className='home'>
            <div className='mainToolBarTrading homeBoxShadow noSelect'>
                <div className='mainMenuButton' onClick={() => setMenuOpen(!menuOpen)}>Menu</div>
                <div className='titleBar'>Dashboard - {menuItems[homePath]?.title}</div>
                <div className='logoutButton' onClick={login.logout}>Logout</div>
            </div>
            {menuOpen && (
                <div className={classNames('mainMenuTrading homeBoxShadow', { menuOverlay: appLayout.menuOverlay })}>
                    {Object.keys(menuItems).filter((itemKey) => (menuItems[itemKey].shouldShow?.() && !menuItems[itemKey].onlyDirect)).map((itemKey) => (
                        <Link
                            className={classNames('menuItem', { selected: homePath === itemKey })}
                            key={itemKey}
                            to={`/${itemKey}`}
                            onClick={() => appLayout.menuOverlay && setMenuOpen(false)}
                        >
                            {menuItems[itemKey].title}
                        </Link>
                    ))}
                    <div
                        className={classNames('menuItem showLogsButton', { selected: logsOpen })}
                        onClick={() => { setLogsOpen(!logsOpen); setMenuOpen(appLayout.menuOverlay ? false : menuOpen); }}
                    >
                        {logsOpen ? 'Hide' : 'Show'} Logs
                    </div>
                </div>
            )}
            <div
                className={classNames('homeContentContainer', { logsOpen, logsFullScreen })}
                style={{
                    gridColumn: `span ${(menuOpen && !appLayout.menuOverlay) ? 1 : 2}`,
                    gridTemplateRows: (logsOpen && !logsFullScreen) ? `1fr ${logsHeight ? `${logsHeight}px` : '1fr'}` : '1fr',
                }}
            >
                {!(logsOpen && logsFullScreen) && (
                    <div className='homeContent'>
                        {menuItems[homePath]?.content()}
                    </div>
                )}
                {logsOpen && (
                    <LogsOverlay
                        changeOvelayHeight={setLogsHeight}
                        logsFullScreen={logsFullScreen}
                        toggleFullScreen={() => setLogsFullScreen(!logsFullScreen)}
                    />
                )}
            </div>
        </div>
    );
}

export default Home;

// // trading view widget example (the script tags can probably just be done on componentDidMount)
// <div class="tradingview-widget-container">
//     <div id="tradingview_ae276"></div>
//     <div class="tradingview-widget-copyright">
//         <a href="https://www.tradingview.com/symbols/BLACKBULL-SPX500/" rel="noopener" target="_blank"><span class="blue-text">SPX500 Chart</span></a>
//         by TradingView
//     </div>
//     <script type="text/javascript" src="https://s3.tradingview.com/tv.js"></script>
//     <script type="text/javascript">
//         new TradingView.widget({
//             autosize: true,
//             symbol: 'BLACKBULL:SPX500',
//             interval: '5',
//             timezone: 'America/New_York',
//             theme: 'dark',
//             style: '1',
//             locale: 'en',
//             toolbar_bg: '#f1f3f6',
//             enable_publishing: false,
//             hide_side_toolbar: false,
//             allow_symbol_change: true,
//             container_id: 'tradingview_ae276'
//         });
//     </script>
// </div>
