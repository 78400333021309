import React, { Component } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ajax } from 'svs-utils/web';
import { Input } from 'svs-utils/react';

import './etrade-login.scss';

class ETradeLogin extends Component {

    constructor(props) {
        super(props);
        var { type } = props;

        this.state = {
            authorizeUrl: 'Loading...',
            verifierToken: '',
        };

        this.ajaxCalls = {
            [`/get${type}OauthInfoFromHash`]: {},
        };
    }

    async componentDidMount() {
        var { navigate, type, urlParams: { hashId } } = this.props;

        if (!hashId) {
            navigate('/404');
            return;
        }

        var endPoint = `/get${type}OauthInfoFromHash`;
        var ajaxCall = this.ajaxCalls[endPoint];
        if (ajaxCall.loading || ajaxCall.loaded) {
            return;
        }
        ajaxCall.loading = true;
        var results = await ajax({ endPoint, data: { hashId } });
        ajaxCall.loading = false;
        ajaxCall.loaded = true;
        if (results.result) {
            this.setState({ authorizeUrl: results.authorizeUrl });
        } else {
            this.setState({ authorizeUrl: results.desc });
        }
    }

    async submitValidator() {
        var { type, urlParams: { hashId } } = this.props;
        var { verifierToken } = this.state;

        try {
            var url = new URL(verifierToken);
            var code = url.searchParams.get('code');
            if (code) {
                verifierToken = code;
            } else {
                // no code param found, just run the decode in case
                verifierToken = decodeURIComponent(verifierToken);
            }
        } catch (error) {
            // not a valid url, just run the decode in case
            verifierToken = decodeURIComponent(verifierToken);
        }

        if (!verifierToken) {
            alert('invalid validator');
            return;
        }

        var results = await ajax({ endPoint: `/submit${type}OauthValidator`, data: { hashId, verifierToken } });
        if (results.result) {
            this.setState({ authorizeUrl: 'Successfully got access token!' });
        } else {
            alert(results.desc);
        }
    }

    render() {
        var { urlParams: { hashId } } = this.props;
        var { authorizeUrl, verifierToken } = this.state;

        return (
            <div className='eTradeLogin'>
                <div></div>
                <div>
                    Login Stuff Here {hashId}
                    <br />
                    <br />
                    Url: {authorizeUrl.startsWith('https') ?
                        <a href={authorizeUrl} target='_blank' rel="noreferrer">Click Here!</a> :
                        authorizeUrl
                    }
                    <div style={{ maxWidth: 300, marginTop: 20 }}>
                        <Input label='Verifier Token' value={verifierToken} onChange={(value) => this.setState({ verifierToken: value })} />
                        <Input type='button' label='Submit Validator' onClick={() => this.submitValidator()} />
                    </div>
                </div>
                <div></div>
            </div>
        );
    }
}

export default function ETradeLoginWrapper(props) {
    var navigate = useNavigate();
    var urlParams = useParams();

    return <ETradeLogin {...props} navigate={navigate} urlParams={urlParams} />;
}
