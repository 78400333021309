import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
// import classNames from 'classnames';
import dateFormat from 'dateformat';

import { ajax, parseDate } from 'svs-utils/web';

import './logs-overlay.scss';

class LogsOverlay extends Component {
    constructor(props) {
        super(props);

        this.state = {
            logs: [],
            status: 'not connected',
        };

        this.needsToScrollDown = false;
        this.scrollContainerRef = React.createRef();

        this.logsOverlayRef = React.createRef();
        this.dragData = {};
        this.dragMouseMoveListener = (event) => this.dragMouseMove(event);
        this.dragMouseUpListener = (event) => this.dragMouseUp(event);
    }

    componentDidMount() {
        this.startLogsListening();
    }

    componentDidUpdate() {
        if (this.needsToScrollDown) {
            this.scrollContainerRef.current.scrollTop = this.scrollContainerRef.current.scrollHeight;
            this.needsToScrollDown = false;
        }
    }

    componentWillUnmount() {
        this.disconnectWs();
    }

    async startLogsListening() {
        var { logs } = this.state;
        if (!this.ws) {
            logs = [];
            logs.push({ key: 'requestingHallPass', content: 'Requesting Hall Pass', timeString: dateFormat('yyyy-mm-dd HH:MM:ss.l') });
            this.setState({ logs });

            this.ws = 1;
            var results = await ajax({ endPoint: '/requestWsHallPass' });
            if (!results.result) {
                this.ws = null;
                logs.push({ key: 'failedToGetHallPass', content: results.desc });
                this.setState({ logs });
                return;
            }

            logs.push({ key: 'gotHallPass', content: 'Got Hall Pass, trying to connect...', timeString: dateFormat('yyyy-mm-dd HH:MM:ss.l') });
            this.setState({ logs });

            // the /temp is in here because redbird doesn't recognize the /ws endpoint when the ?token= is after it
            var protocol = window.location.protocol === 'http:' ? 'ws' : 'wss';
            var host = window.location.host;
            if (host.startsWith('dev.') && host.endsWith(':2999')) {
                host = host.replace(':2999', ':3000');
            }

            this.ws = new WebSocket(`${protocol}://${host}/api/ws/botLogs?token=${results.token}`);
            this.ws.onopen = () => {
                this.ws.send('something web');
                logs.push({ key: 'wsconnected', content: 'WebSocket Connected', timeString: dateFormat('yyyy-mm-dd HH:MM:ss.l') });
                this.setState({ logs, status: 'connected' });
            };
            this.ws.onmessage = (event) => this.handleNewMessage(event);
            this.ws.onerror = (...args) => console.log('error connecting ws', args);
            this.ws.onclose = (event) => {
                console.log('ws close event', event);
                if (this.state.status !== 'disconnected by me') {
                    this.setState({ status: 'connection closed' })
                }
            };

            // on will unmount, close the ws
        }
    }

    disconnectWs() {
        if (this.ws && typeof this.ws === 'object') {
            this.ws.close();
            this.ws = null;
            this.setState({ status: 'disconnected by me' });
        }
    }

    handleConnectButtonClicked() {
        var { status } = this.state;

        if (status === 'connected') {
            this.disconnectWs();
        } else {
            this.startLogsListening();
        }
    }

    handleNewMessage(event) {
        var { logs } = this.state;

        var data = JSON.parse(event.data);
        if (data.type === 'logRelay') {
            var content = data.message;
            if (Array.isArray(content)) {
                content = content.map((item) => ((typeof item === 'object') ? JSON.stringify(item) : item)).join(' ');
            }
            data.content = content;

            data.time = parseDate(data.time);
            data.timeString = dateFormat(data.time, 'yyyy-mm-dd HH:MM:ss.l');

            var randomString = Math.floor(Math.random() * (999 - 100 + 1) + 100).toString();
            data.key = dateFormat(data.time, 'yyyymmddHHMMssl') + randomString;

            logs.push(data);
            while (logs.length > 1000) {
                logs.splice(0, 1);
            }

            var scrollHeight = this.scrollContainerRef.current.scrollHeight - this.scrollContainerRef.current.clientHeight;
            var scrollTop = this.scrollContainerRef.current.scrollTop;
            if (scrollHeight === scrollTop) {
                this.needsToScrollDown = true;
            }

            this.setState({ logs });
        }
    }

    dragMouseDown(event) {
        var { logsOverlayRef, dragData } = this;

        if (event.button !== 0) {
            return;
        }

        var obj = logsOverlayRef.current;
        var box = obj.getBoundingClientRect();

        dragData.offsetY = event.clientY - box.y;
        dragData.bottom = box.bottom;

        var parent = obj.parentElement;
        var parentBox = parent.getBoundingClientRect();
        dragData.maxHeight = parentBox.height * 0.8;
        dragData.minHeight = parentBox.height * 0.2;

        window.addEventListener('mousemove', this.dragMouseMoveListener);
        window.addEventListener('mouseup', this.dragMouseUpListener);
        document.getElementsByTagName('body')[0].classList.add('noSelect');
    }

    dragMouseMove(event) {
        var { dragData } = this;
        var { changeOvelayHeight } = this.props;

        var newTop = event.clientY - dragData.offsetY;
        var newHeight = dragData.bottom - newTop;
        newHeight = Math.max(newHeight, dragData.minHeight);
        newHeight = Math.min(newHeight, dragData.maxHeight);

        changeOvelayHeight(newHeight);
    }

    dragMouseUp(event) {
        this.dragData = {}

        window.removeEventListener('mousemove', this.dragMouseMoveListener);
        window.removeEventListener('mouseup', this.dragMouseUpListener);
        document.getElementsByTagName('body')[0].classList.remove('noSelect');
    }

    render() {
        var { changeOvelayHeight, logsFullScreen, toggleFullScreen } = this.props;
        var { logs, status } = this.state;

        return (
            <div className='logsOverlay' ref={this.logsOverlayRef}>
                <div className='logsMenuBar'>
                    <div className='statusIndicator'>Status: {status}</div>
                    <div className='logsDragHandle' onMouseDown={(event) => this.dragMouseDown(event)}></div>
                    <div className='restartButton' onClick={() => this.handleConnectButtonClicked()}>{status === 'connected' ? 'Disconnect' : 'Connect'}</div>
                    <div className='fullScreenButton' onClick={() => toggleFullScreen() || changeOvelayHeight(null)}>{logsFullScreen ? 'Half Screen' : 'Full Screen'}</div>
                </div>
                <div className='logsScrollContainer' ref={this.scrollContainerRef}>
                    <div className='logsContainer'>
                        {logs.map((log, i) => (
                            <div className='logItem' key={log.key}>[<span className='time'>{log.timeString}</span>] {log.content}</div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

export default LogsOverlay;
