import React, { Component } from 'react';
// import dateFormat from 'dateformat';
import classNames from 'classnames';

import { Dialog, Input, useAppLayout } from 'svs-utils/react';

import './edit-bot.scss';

class EditBot extends Component {
    state = {
        // storeOptionsData
        symbols: [],

        // storePriceData
        minuteBarSymbols: [],
        streamingSymbols: [],
    };

    componentDidMount() {
        var { editParams = {} } = this.props;
        var { symbols, minuteBarSymbols, streamingSymbols } = this.state;

        this.setState({
            // storeOptionsData
            symbols: editParams.symbols || symbols,

            // storePriceData
            minuteBarSymbols: editParams.minuteBarSymbols || minuteBarSymbols,
            streamingSymbols: editParams.streamingSymbols || streamingSymbols,
        });
    }

    componentDidUpdate(prevProps) {
        var { editParams } = this.props;
        var { symbols, minuteBarSymbols, streamingSymbols } = this.state;
        if (prevProps.editParams.botId !== editParams.botId) {
            this.setState({
                // storeOptionsData
                symbols: editParams.symbols || symbols,

                // storePriceData
                minuteBarSymbols: editParams.minuteBarSymbols || minuteBarSymbols,
                streamingSymbols: editParams.streamingSymbols || streamingSymbols,
            });
        }
    }

    handleInputChange(key, value) {
        this.setState({ [key]: value });
    }

    handleSubmitClick() {
        var { editParams: { botId } = {}, strategy, submit } = this.props;
        var { symbols, minuteBarSymbols, streamingSymbols } = this.state;

        if (strategy === 'storeOptionsData') {
            symbols = symbols.filter((symbol) => !!symbol);
            submit({ strategy, botId, symbols });
        } else if (strategy === 'storePriceData') {
            minuteBarSymbols = minuteBarSymbols.filter((symbol) => !!symbol);
            streamingSymbols = streamingSymbols.filter((symbol) => !!symbol);
            submit({ strategy, botId, minuteBarSymbols, streamingSymbols });
        }
    }

    onEnter(event, func) {
        if (event.key === 'Enter') {
            func(true);
        }
    }

    render() {
        var { appLayout, close, strategy, editParams } = this.props;
        var { symbols, minuteBarSymbols, streamingSymbols } = this.state;

        var smallDialog = appLayout.isMobile || ['XS', 'S'].includes(appLayout.layoutSize);

        var fields = [];
        var width = smallDialog ? 400 : 700;
        if (strategy === 'storeOptionsData') {
            fields = (
                <div style={{ gridColumn: 'span 2' }}>
                    <Input type='singlePill' label='Symbols' value={symbols} onChange={(value) => this.handleInputChange('symbols', value)} />
                </div>
            );
            width = 400;
        } else if (strategy === 'storePriceData') {
            fields = (
                <div style={{ gridColumn: 'span 2' }}>
                    <Input type='singlePill' label='Minute Bar Symbols' value={minuteBarSymbols} onChange={(value) => this.handleInputChange('minuteBarSymbols', value)} />
                    <Input type='singlePill' label='Streaming Symbols' value={streamingSymbols} onChange={(value) => this.handleInputChange('streamingSymbols', value)} />
                </div>
            );
            width = 400;
        }

        return (
            <Dialog
                className='dialogEditBot'
                close={close}
                header={(editParams?.botId ? `Edit Bot - ${editParams.botName}` : 'Add New Bot')}
                height={smallDialog ? 538 : 500}
                width={width}
            >
                <div className={classNames('editBotContainer', { smallDialog })}>
                    {fields}
                    <Input
                        type='button'
                        label='Submit'
                        onClick={() => this.handleSubmitClick()}
                        style={{ gridColumn: `span ${smallDialog ? 1 : 2}` }}
                    />
                </div>
            </Dialog>
        );
    }
}

export default function EditBotWrapper(props) {
    let appLayout = useAppLayout();

    return <EditBot {...props} appLayout={appLayout} />;
}
