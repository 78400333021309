import React, { Component } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import dateFormat from 'dateformat';

import { calculateTimeSpanString, parseDate } from 'svs-utils/web';
import { Table2 } from 'svs-utils/react';

class RanTestsTable extends Component {

    handleCompareTestsClick(tests, event) {
        if (tests.length < 2) {
            return alert('Please select at least two tests to compare');
        }
        if (tests.length > 5) {
            return alert('You can only compare up to 5 tests for now');
        }

        var { navigate } = this.props;

        var path = `/compareresults?tests=${tests.map((test) => test.testId).join(',')}`
        if (event.metaKey || event.ctrlKey) {
            window.open(path, '_blank');
        } else {
            navigate(path);
        }
    }

    render() {
        var { clickGroups, showCompare = false, showCurrentDate = false, showName = true, showRowCount = true, tests } = this.props;

        var daysOfWeek = { 1: 'M', 2: 'Tu', 3: 'W', 4: 'Th', 5: 'F' };
        var tableHeaders = [
            { key: 'testId', label: 'ID', dataType: 'number', className: 'centered', width: 40 },
            { key: 'runDate', label: 'Test Run Date', dataType: 'date', width: 140, dateFormat: 'mm/dd/yy hh:MM tt' },
            { key: 'symbol', label: 'Symbol', dataType: 'string', width: 80 },
            { key: 'startDate', label: 'Start Date', dataType: 'date', width: 140 },
            { key: 'endDate', label: 'End Date', dataType: 'date', width: 140 },
            { key: 'timespan', label: 'Timespan', dataType: 'string', width: 100 },
            { key: 'startingBalance', label: 'Starting Balance', dataType: 'number', width: 100, format: 'money' },
            { key: 'endingBalance', label: 'Ending Balance', dataType: 'number', width: 100, format: 'money' },
            { key: 'profit', label: 'Profit', dataType: 'number', format: 'money' },
            { key: 'profitPercent', label: 'Profit Percent', dataType: 'number', width: 100, format: 'percent' },
            { key: 'goalProfitPercent', label: 'Goal Profit', dataType: 'number', className: 'centered', width: 80, format: 'simplePercent' },
            { key: 'maxSpread', label: 'Max Spread', dataType: 'number', className: 'centered', width: 100 },
            { key: 'slippage', label: 'Slippage', dataType: 'number', className: 'centered', width: 100 },
            { key: 'daysOfWeek', label: 'Days of Week', dataType: 'string', className: 'centered', width: 100 },
            { key: 'startTime', label: 'Start Time', dataType: 'string', className: 'centered', width: 100 },
            { key: 'takeProfit', label: 'Take Profit', dataType: 'string', className: 'centered', width: 100 },
        ];

        if (showName) {
            tableHeaders.splice(1, 0, { key: 'name', label: 'Name', dataType: 'string', width: 200 });
        }

        if (showCurrentDate) {
            tableHeaders.splice(6, 0, { key: 'currentDate', label: 'Current Date', dataType: 'date', width: 140 });
        }

        var tableRows = tests.map((test) => ({
            testId: test.testId,
            name: test.name,
            runDate: test.testStarted,
            symbol: test.params.symbol,
            startDate: test.startDate || test.params.startDate,
            currentDate: test.results.currentTime || '',
            endDate: test.endDate || test.params.endDate,
            timespan: calculateTimeSpanString(test.startDate || test.params.startDate, test.endDate || test.params.endDate),
            startingBalance: test.startingBalance || test.params.cash,
            endingBalance: test.results.endingBalance || test.params.cash,
            profit: test.results.profit || 0,
            profitPercent: test.results.profitPercent || 0,
            goalProfitPercent: test.params.tradePercent ? test.params.tradePercent / 100 : test.params.goalProfitPercent,
            maxSpread: test.params.maxSpread,
            slippage: test.params.slippage || 0,
            daysOfWeek: test.params.approvedDays.map((d) => daysOfWeek[d] || d).join(' '),
            startTime: dateFormat(parseDate(test.params.startTime), 'h:MM tt'),
            takeProfit: test.params.shouldTakeProfit ? `${test.params.takeProfit * 100}%` : 'No',
            className: classNames({
                winningTest: test.results.profit >= 0,
                losingTest: test.results.profit < 0,
            }),
            noContextObject: test,
        }));

        var checkedActions = [
            { label: 'Compare Tests', onClick: (tests, event) => this.handleCompareTestsClick(tests, event) },
        ];

        return (
            <Table2
                headers={tableHeaders}
                rows={tableRows}
                clickGroups={clickGroups}
                maxHeight={500}
                showRowCount={showRowCount}
                includeCheckbox={showCompare}
                checkedActions={checkedActions}
            />
        );
    }
}

export default function RanTestsTableWrapper(props) {
    var navigate = useNavigate();
    var urlParams = useParams();

    return <RanTestsTable {...props} navigate={navigate} urlParams={urlParams} />;
}
